import Prism from 'prismjs';
import "prismjs-components-importer/esm/prism-abap";
import "prismjs-components-importer/esm/prism-apacheconf";
import "prismjs-components-importer/esm/prism-bash";
import "prismjs-components-importer/esm/prism-c";
import "prismjs-components-importer/esm/prism-clojure";
import "prismjs-components-importer/esm/prism-coffeescript";
import "prismjs-components-importer/esm/prism-cpp";
import "prismjs-components-importer/esm/prism-csharp";
import "prismjs-components-importer/esm/prism-dart";
import "prismjs-components-importer/esm/prism-docker";
import "prismjs-components-importer/esm/prism-elixir";
import "prismjs-components-importer/esm/prism-erb";
import "prismjs-components-importer/esm/prism-erlang";
import "prismjs-components-importer/esm/prism-excel-formula";
import "prismjs-components-importer/esm/prism-fsharp";
import "prismjs-components-importer/esm/prism-go";
import "prismjs-components-importer/esm/prism-graphql";
import "prismjs-components-importer/esm/prism-haml";
import "prismjs-components-importer/esm/prism-haskell";
import "prismjs-components-importer/esm/prism-http";
import "prismjs-components-importer/esm/prism-toml";
import "prismjs-components-importer/esm/prism-java";
import "prismjs-components-importer/esm/prism-julia";
import "prismjs-components-importer/esm/prism-kotlin";
import "prismjs-components-importer/esm/prism-less";
import "prismjs-components-importer/esm/prism-lisp";
import "prismjs-components-importer/esm/prism-makefile";
import "prismjs-components-importer/esm/prism-markdown";
import "prismjs-components-importer/esm/prism-nginx";
import "prismjs-components-importer/esm/prism-objectivec";
import "prismjs-components-importer/esm/prism-ocaml";
import "prismjs-components-importer/esm/prism-perl";
import "prismjs-components-importer/esm/prism-php";
import "prismjs-components-importer/esm/prism-powershell";
import "prismjs-components-importer/esm/prism-purebasic";
import "prismjs-components-importer/esm/prism-python";
import "prismjs-components-importer/esm/prism-r";
import "prismjs-components-importer/esm/prism-ruby";
import "prismjs-components-importer/esm/prism-rust";
import "prismjs-components-importer/esm/prism-scala";
import "prismjs-components-importer/esm/prism-scss";
import "prismjs-components-importer/esm/prism-solidity";
import "prismjs-components-importer/esm/prism-sql";
import "prismjs-components-importer/esm/prism-swift";
import "prismjs-components-importer/esm/prism-typescript";
import "prismjs-components-importer/esm/prism-yaml";

type ColorType = string;

const colors: ColorType[] = ["#EEF7FE", "#DBF8EA", "#E8EEFF", "#F4EFFA"];

let shuffledColors: ColorType[] = shuffleArray([...colors]);

function shuffleArray<T>(array: T[]): T[] {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

export const checklistParser = (block: any) => {
    const formattedData = block.data.items.map((item: any) => {
        return `<input type="checkbox" /> ${item.text} <br />`
    });
    
    return formattedData.join('\n');
}

export const codeParser = (block: any) => {
    const html = Prism.highlight(block.data.code, Prism.languages[block.data.language], block.data.language);
    return `<pre class="prism-display-pre language-${block.data.language}"><code class="prism prism-display-code language-${block.data.language}">${html}</code></pre>`
}

export const getRandomColor = (): ColorType => {
    if (shuffledColors.length === 0) {
        shuffledColors = shuffleArray([...colors]);
    }
    return shuffledColors.pop()!;
};
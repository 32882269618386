// External imports
import { ReactNode, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

// Internal imports
import "./layout.css";
import Footer from "../Footer";
import Toastify from "../../feedback/Alert";
import LayoutHeader from "./LayoutHeader";
import LayoutSidebar from "./LayoutSidebar";
import { RootState } from "../../../store";
import { IUser } from "../../../interfaces/user";

export default function Layout({ children }: { children: ReactNode }) {
    const userData: IUser = useSelector((state: RootState) => state.userData);

    const navigate = useNavigate();
    const [menu, setMenu] = useState(true);
    const [panel, setPanel] = useState(false);
    const [sidePanel, setSidePanel] = useState(false);
    const { pathname } = useLocation();

    const handlemenuToggle = () => {
        setMenu(!menu);
    };

    const handlePanelToggle = (type: string) => {
        type === "panel" ? setPanel(!panel) : setSidePanel(!sidePanel);
    };

    const closePanel = (type: string) => {
        type === "panel" ? setPanel(false) : setSidePanel(false);
    };

    return (
        <section
            className="flex bg-gray-100 w-full min-h-screen"
            x-data="{panel:false, menu:true}"
        >
            <LayoutSidebar menu={menu} userData={userData} />
            <div className="flex-grow text-gray-800">
                <LayoutHeader toggleMenu={handlemenuToggle} togglePanel={handlePanelToggle} panel={panel} initClosePanel={closePanel} userData={userData} />
                <main className="p-2 sm:p-10 space-y-2 dark:bg-gray-600">
                    <section className="font-semibold text-gray-500">
                        <button
                            onClick={() => navigate(-1)}
                            className={`flex text-sm mb-8 text-blue-600 items-center mb-4 ${
                                pathname === "/dashboard" && "hidden"
                            }`}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="10"
                                width="12"
                                viewBox="0 0 384 512"
                                fill="currentColor"
                                className="text-[#2E68FB] dark:text-white"
                            >
                                <path d="M380.6 81.7c7.9 15.8 1.5 35-14.3 42.9L103.6 256 366.3 387.4c15.8 7.9 22.2 27.1 14.3 42.9s-27.1 22.2-42.9 14.3l-320-160C6.8 279.2 0 268.1 0 256s6.8-23.2 17.7-28.6l320-160c15.8-7.9 35-1.5 42.9 14.3z" />
                            </svg>
                            <span className="underline dark:text-gray-300">Back</span>
                        </button>
                        {/* Page content section */}
                        {children}
                        {/* End of page content section */}
                    </section>
                </main>
                <Footer type="layout" />
            </div>
            <Toastify />
        </section>
    );
}

// External imports
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import 'react-loading-skeleton/dist/skeleton.css'

// Internal imports
import { RootState } from "./store";

// Component Imports
import ProtectedRoute from "./components/layout/ProtectedRoute";
import DashboardEntry from "./pages/dashboard/dashboardEntry";
import Register from "./pages/onboarding/Register";
import Login from "./pages/onboarding/Login";
import ForgotPassword from "./pages/account/ForgotPassword";
import SsoSuccess from "./pages/onboarding/SsoSuccess";
import ParentRoute from "./routes/parentRoute";
import VerificationLoader from "./pages/VerificationLoader";
import PasswordReset from "./pages/account/PasswordReset";
import SavedTemplates from "./pages/templates/SavedTemplates";
import BrowseTemplates from "./pages/templates/BrowseTemplates";
import PreviewTemplate from "./pages/templates/PreviewTemplate";
import CreateArticle from "./pages/articles/CreateArticle";
import SavedArticles from "./pages/articles/SavedArticles";
import DraftsArticle from "./pages/articles/DraftsArticles";
import PreviewArticle from "./pages/articles/PreviewArticle";

export default function App() {
  const userData = useSelector((state: RootState) => state.userData);
  const isSignedIn = userData.user.isLoggedIn;

  return (
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<Login />} />
            <Route path="register" element={<Register />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="verify-token" element={<VerificationLoader />} />
            <Route path="signin-success" element={<SsoSuccess />} />
            <Route path="password-reset" element={<PasswordReset />} />
            <Route path="invite" element={<VerificationLoader />} />
            <Route
                path="dashboard"
                element={
                    <ProtectedRoute isSignedIn={isSignedIn}>
                    <DashboardEntry />
                    </ProtectedRoute>
                }
            />
            <Route path="templates" element={<ParentRoute />}>
            <Route
                path="saved"
                element={
                    <ProtectedRoute isSignedIn={isSignedIn}>
                        <SavedTemplates />
                    </ProtectedRoute>
                }
            />
            <Route
                path="browse"
                element={
                    <ProtectedRoute isSignedIn={isSignedIn}>
                        <BrowseTemplates />
                    </ProtectedRoute>
                }
            />
            <Route
                path="preview/:id"
                element={
                    <ProtectedRoute isSignedIn={isSignedIn}>
                        <PreviewTemplate />
                    </ProtectedRoute>
                }
            />
            </Route>
            <Route path="articles" element={<ParentRoute />}>
            <Route
                path="draft/:id"
                element={
                    <ProtectedRoute isSignedIn={isSignedIn}>
                        <CreateArticle />
                    </ProtectedRoute>
                }
            />
            <Route
                path="preview/:id"
                element={
                    <ProtectedRoute isSignedIn={isSignedIn}>
                        <PreviewArticle />
                    </ProtectedRoute>
                }
            />
            <Route
                path="saved"
                element={
                    <ProtectedRoute isSignedIn={isSignedIn}>
                        <SavedArticles />
                    </ProtectedRoute>
                }
            />
            <Route
                path="drafts"
                element={
                    <ProtectedRoute isSignedIn={isSignedIn}>
                        <DraftsArticle />
                    </ProtectedRoute>
                }
            />
            </Route>
        </Routes>
    </BrowserRouter>
  );
}

// External imports
import React, {MouseEventHandler} from "react";
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';

// Internal imports
import avatar1 from '../../../assets/dashboard/avatar1.svg';
import AppModeSwitcher from "../AppModeSwitcher";
import { IUser } from '../../../interfaces/user';
import { logout } from "../../../services/account";
import { LocalStorage } from "../../../helpers/localStorageHelper";

type LayoutHeaderProps = {
    toggleMenu: MouseEventHandler<HTMLDivElement>;
    togglePanel: Function;
    panel: boolean;
    initClosePanel: Function; 
    userData: IUser
}

const LayoutHeader: React.FC<LayoutHeaderProps> = ({toggleMenu, togglePanel, panel, initClosePanel, userData}) => {
    const handleLogout = async () => {
        try {
            // Call logout API
            const { status, message } = await logout();
            if (status !== 200) {
                toast.error(message);
            }

            // Delete data from redux store and redirect user to login page
            LocalStorage.removeItem("persist:root");
            window.location.href = "/";
        } catch (err) {
            toast.error("Unable to logout");
        }
    };

    return (
        <header className="flex items-center h-17 px-6 sm:px-10 bg-white border-b border-gray-200 dark:bg-gray-900 dark:border-b-gray-400">
            <div
                className="mr-8 cursor-pointer"
                onClick={toggleMenu}
            >
                <svg
                    className="w-8 h-8 text-gray-600 dark:text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 6h16M4 12h16M4 18h16"
                    />
                </svg>
            </div>
            <div className="flex flex-shrink-0 items-center ml-auto">
                <button
                    className="relative inline-flex items-center p-3 hover:bg-gray-100 focus:bg-gray-100 rounded-lg dark:hover:bg-gray-600 dark:focus:bg-gray-600"
                    onClick={() => togglePanel("panel")}
                >
                    <div className="hidden md:flex md:flex-col md:items-end md:leading-tight">
                        <span className="font-semibold text-xs dark:text-white">
                            {userData.user.firstName +
                                " " +
                                userData.user.lastName}
                        </span>
                        <span className="text-xs text-gray-600 dark:text-white">
                            User
                        </span>
                    </div>
                    <span className="h-8 w-8 ml-2 sm:ml-3 mr-2 bg-gray-100 rounded-full overflow-hidden">
                        <img
                            src={avatar1}
                            alt="user profile photo"
                            className="h-full w-full object-cover"
                        />
                    </span>
                    <svg
                        aria-hidden="true"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="hidden sm:block h-6 w-6 text-gray-300"
                    >
                        <path
                            fillRule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clipRule="evenodd"
                        />
                    </svg>
                </button>
                {panel && (
                    <div
                        className="absolute top-20 bg-white border rounded-md p-2 w-56 z-40"
                        onMouseLeave={() => initClosePanel("panel")}
                    >
                        <div className="p-2 hover:bg-blue-100 cursor-pointer flex flex-row gap-2">
                            <svg
                                viewBox="0 0 448 512"
                                stroke="currentColor"
                                className="h-6 w-6"
                            >
                                <path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" />
                            </svg>
                            Profile
                        </div>
                        <div className="p-2 hover:bg-blue-100 cursor-pointer flex flex-row gap-2">
                            <svg
                                viewBox="0 0 512 512"
                                stroke="currentColor"
                                className="h-6 w-6"
                            >
                                <path d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z" />
                            </svg>
                            Settings
                        </div>
                        <div
                            className="p-2 hover:bg-blue-100 cursor-pointer flex flex-row gap-2"
                            onClick={handleLogout}
                        >
                            <svg
                                viewBox="0 0 512 512"
                                stroke="currentColor"
                                className="h-6 w-6"
                            >
                                <path
                                    d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"
                                    fill="currentColor"
                                />
                            </svg>
                            Logout
                        </div>
                    </div>
                )}
                <div className="space-x-1">
                    <AppModeSwitcher />
                </div>
                <div className="border-l pl-3 ml-3 space-x-1">
                    <button className="relative p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-600 focus:bg-gray-100 focus:text-gray-600 rounded-full dark:hover:bg-gray-600">
                        <span className="sr-only">Notifications</span>
                        <span className="absolute top-0 right-0 h-2 w-2 mt-1 mr-2 bg-red-500 rounded-full"></span>
                        <span className="absolute top-0 right-0 h-2 w-2 mt-1 mr-2 bg-red-500 rounded-full animate-ping"></span>
                        <svg
                            aria-hidden="true"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            className="h-5 w-5 dark:text-white dark:hover:text-white"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                            />
                        </svg>
                    </button>
                    <button className="relative p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-600 focus:bg-gray-100 focus:text-gray-600 rounded-full dark:hover:bg-gray-600">
                        <span className="sr-only">Log out</span>
                        <svg
                            onClick={handleLogout}
                            aria-hidden="true"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            className="h-5 w-5 dark:text-white dark:hover:text-white"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                            />
                        </svg>
                    </button>
                </div>
            </div>
        </header>
    )
}

export default LayoutHeader;
const initialState = {
    user: {
        accountPlan: '',
        email: '',
        firstName: '',
        id: 0,
        isLoggedIn: false,
        isOnboarded: false,
        isPlanActive: false,
        isPlanModalShown: {
            isPlanModalShown: false,
        },
        lastName: '',
        planExpiryDate: new Date().toString(),
        refreshToken: ''
    },
    onboardingData: {
        workspaceName: '',
        workspaceDescription: '',
        invitees: [],
    },
}

const userReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case 'SAVE_USER_DATA':
            return {
                ...state,
                user: action.payload,
            }
        case 'UPDATE_USER_DATA':
            return {
                ...state,
                user: {
                    ...state.user,
                    [action.payload]: action.payload,
                },
            }
        case 'UPDATE_USER_PLAN_MODAL_STATUS':
            return {
                ...state,
                user: {
                    ...state.user,
                    isPlanModalShown: action.payload,
                },
            }
        case 'UPDATE_ONBOARDING_DATA':
            return {
                ...state,
                onboardingData: {
                    ...state.onboardingData,
                    ...action.payload,
                },
            }
        default:
            return state;
    }
}

export default userReducer;
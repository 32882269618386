// External imports
import { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import editorJsHtml from "editorjs-html";
import Skeleton from "react-loading-skeleton";

// Internal imports
import Layout from "../../components/layout/main/Layout";
import Button from "../../components/form/Button";
import ArticlePreview from "../../components/dataDisplay/ArticlePreview";
import { retrieveTemplateContent } from "../../services/template";
import { createArticle } from "../../services/article";
import { checklistParser, codeParser } from "../../helpers/templateHelper";

const has = Object.prototype.hasOwnProperty;

const EditorJsToHtml = editorJsHtml({
    checklist: checklistParser,
    code: codeParser,
});
let html: any = [];

export default function PreviewTemplate() {
    const navigate = useNavigate();
    const params = useParams();

    const draftData = useRef<any>();

    if (draftData.current && has.call(draftData.current, "time")) {
        html = EditorJsToHtml.parse(draftData.current);
    }

    const [isDataLoading, setIsDataLoading] = useState(true);
    const [articleData, setArticleData] = useState({
        title: "",
        subtitle: "",
        coverImageUrl: "",
        reference: "",
    });

    useEffect(() => {
        const fetchTemplateContent = async () => {
            if (params.id) {
                const { status, data, message } = await retrieveTemplateContent(
                    params.id
                );
                if (status === 200) {
                    const { title, subtitle, coverImageUrl, content, reference } = data;
                    draftData.current = content;

                    setArticleData({
                        ...articleData,
                        title,
                        subtitle,
                        coverImageUrl,
                        reference
                    });
                    setIsDataLoading(false);
                }

                if (status === 404) {
                    setIsDataLoading(false);
                    alert(message);
                    navigate("/templates/browse");
                    return;
                }

                return false;
            }
        };

        fetchTemplateContent();
    }, [params]);

    const useTemplate = async () => {
        const { status, data, message } = await createArticle({
            title: articleData.title,
            subtitle: articleData.subtitle,
            coverImageUrl: articleData.coverImageUrl,
            content: draftData.current,
        });
        if (status !== 200) {
            toast.error(message);
            return;
        }

        navigate(`/articles/draft/${data.id}`);
    };

    if (isDataLoading) {
        return (
            <Layout>
                <section className="bg-white p-4 text-black">
                    <Skeleton count={8} />
                </section>
            </Layout>
        );
    }

    return (
        <Layout>
            <section className="mb-4 flex flex-row justify-between">
                <p></p>
                <Button
                    label="Use Template"
                    classValue="inline-flex items-center justify-center px-2 text-sm font-semibold leading-6 text-white transition duration-150 ease-in-out rounded-md shadow bg-brand py-3 px-4 h-10 rounded-md text-white text-lg font-bold focus:border border-gray-700"
                    onClick={useTemplate}
                />
            </section>
            <ArticlePreview articleData={articleData} html={html} />
        </Layout>
    );
}

// External imports
import { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

// Internal imports
import Logo from '../assets/logo.svg';
import { verifyPasswordResetLink } from "../services/account";
import { LocalStorage } from "../helpers/localStorageHelper";

export default function VerificationLoader() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    useEffect(() => {
        const callVerifyApi = async () => {
            try {
                if (searchParams.get('token')) {
                    const { status } = await verifyPasswordResetLink({
                        url: `/account/verify-link/${searchParams.get('token')}`
                    });
                    if (status !== 200) {
                        navigate('/');
                        return;
                    }

                    LocalStorage.setItem('reset-token', searchParams.get('token'));
                    navigate('/password-reset');
                }
            } catch (err) {
                navigate('/');
                return;
            }
        }
        callVerifyApi();
    }, [searchParams, navigate]);

    return (
        <>
            <div className="bg-gradient-to-r from-blue-100 to-blue-100 bg-opacity-10 h-screen">
                <div className="bg-cover bg-no-repeat bg-center" style={{ backgroundImage: `url('../../assets/onboarding/bg.png')` }}>
                    <div className="px-4 lg:px-8 py-4">
                        <div className='mt-4'>
                            <img src={Logo} alt="Logo" width={100} height={100} />
                        </div>
                        <div className='fixed inset-0 flex gap-4 items-center justify-center'>
                            <svg className="w-20 h-20 text-white animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none"
                                viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="blue" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                                </path>
                            </svg>
                            <p className='text-xl font-bold'>Verifying...Please wait!!!</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
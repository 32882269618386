import { Axios } from "../helpers/axiosHelper";
import { ArticleFormProps } from "../interfaces/article";

let status: number;
let message: string;
let data: any;

export const fetchArticleData = async (articleId: string) => {
    try {
        const response = await Axios({
            url: `/article/${articleId}`, 
            method: 'get', 
        });
        status = 200;
        message = response.message;
        data = response.data;
    } catch (err: any) {
        status = err.response.status;
        message = err.response.data.message;
    }
    return { status, message, data };
}

export const createArticle = async (payload: ArticleFormProps) => {
    try {
        const response = await Axios({
            url: '/article/create', 
            method: 'post',
            body: payload,
        });
        status = 200;
        message = response.message;
        data = response.data;
    } catch (err: any) {
        status = err.response.status;
        message = err.response.data.message;
    }
    return { status, message, data };
}

export const updateArticle = async (payload: ArticleFormProps, articleId: string) => {
    try {
        const response = await Axios({
            url: `/article/${articleId}`, 
            method: 'patch',
            body: payload,
        });
        status = 200;
        message = response.message;
        data = response.data;
    } catch (err: any) {
        status = err.response.status;
        message = err.response.data.message;
    }
    return { status, message, data };
}

export const fetchUserDrafts = async () => {
    try {
        const response = await Axios({
            url: `/article/drafts`, 
            method: 'get',
        });
        status = 200;
        message = response.message;
        data = response.data;
    } catch (err: any) {
        status = err.response.status;
        message = err.response.data.message;
    }
    return { status, message, data };
}

export const fetchUserSavedArticles = async () => {
    try {
        const response = await Axios({
            url: `/article/published`, 
            method: 'get',
        });
        status = 200;
        message = response.message;
        data = response.data;
    } catch (err: any) {
        status = err.response.status;
        message = err.response.data.message;
    }
    return { status, message, data };
}

export const uploadCoverImage = async (payload: FormData, articleId: string) => {
    try {
        const response = await Axios({
            url: `/article/${articleId}/upload-image`, 
            method: 'post',
            body: payload,
        });
        status = 200;
        message = response.message;
        data = response.data;
    } catch (err: any) {
        status = err.response.status;
        message = err.response.data.message;
    }
    return { status, message, data };
}

export const removeCoverImage = async (articleId: string) => {
    try {
        const response = await Axios({
            url: `/article/${articleId}/remove-image`, 
            method: 'patch',
        });
        status = 200;
        message = response.message;
        data = response.data;
    } catch (err: any) {
        status = err.response.status;
        message = err.response.data.message;
    }
    return { status, message, data };
}

export const exportArticleToMarkdown = async (articleId: string) => {
    try {
        const response = await Axios({
            url: `/article/${articleId}/export-to-md`, 
            method: 'get',
        });
        status = 200;
        message = 'Article exported to markdown successfully';
        data = response;
    } catch (err: any) {
        status = err.response.status;
        message = err.response.data.message;
    }
    return { status, message, data };
}

export const deleteDraftArticle = async (articleId: string) => {
    try {
        const response = await Axios({
            url: `/article/${articleId}`, 
            method: 'delete',
        });
        status = 200;
        message = response.message;
        data = response;
    } catch (err: any) {
        status = err.response.status;
        message = err.response.data.message;
    }
    return { status, message, data };
}
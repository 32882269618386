// External imports
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';

// Internal imports
import './dashboard.css';
import Layout from '../../components/layout/main/Layout';
import Button from '../../components/form/Button';
import Datachart from '../../components/dataDisplay/Chart';
import activityLogImg1 from '.././../assets/dashboard/activity-log-icon1.svg';
import activityLogImg2 from '.././../assets/dashboard/activity-log-icon2.svg';
import activityLogImg3 from '.././../assets/dashboard/activity-log-icon3.svg';
import { RootState } from '../../store';
import {
    fetchDashboardStatisticsCount,
    fetchGraphicalDataForTotalArticles,
} from '../../services/dashboardStatistics';
import { fetchUserActivityLogs } from '../../services/account';
import { getRandomImage } from '../../helpers/globalHeper';

export default function DashboardEntry() {
    const dispatch = useDispatch();
    const userData = useSelector((state: RootState) => state.userData);

    const [activityLogs, setActivityLogs] = useState([]);
    const [totalArticlesChartData, setTotalArticlesChartData] = useState({
        months: [],
        count: [],
    });
    const [dashboardCountData, setDashboardCountData] = useState({
        total_articles: 0,
        published_articles: 0,
        saved_templates: 0,
        drafts: 0,
    });
    const [skeletonState, setSkeletonState] = useState({
        account_stats: false,
        activity_log: false,
    });

    const getTotalArticlesChartData = async (yearValue: number) => {
        const { status, message, data } =
            await fetchGraphicalDataForTotalArticles(yearValue);
        if (status !== 200) {
            toast.error(message);
            return;
        }

        setTotalArticlesChartData({ months: data.months, count: data.value });
    };

    const triggerFetchForActivityLogs = async () => {
        const { status, data } = await fetchUserActivityLogs();
        if (status !== 200) {
            return false;
        }

        setActivityLogs(data);
        setSkeletonState((prevData) => {
            const payload = {
                ...prevData,
                activity_log: true,
            };

            return payload;
        });
    };

    const handleRandomImageRetrieval = () => {
        return getRandomImage([
            activityLogImg1,
            activityLogImg2,
            activityLogImg3,
        ]);
    };

    const closeModal = () => {
        document.getElementById('publish-modal')?.classList.add('hidden');

        dispatch({
            type: 'UPDATE_USER_PLAN_MODAL_STATUS',
            payload: {
                isPlanModalShown: true
            }
        });
    };

    useEffect(() => {
        const getDashboardStatisticsCount = async () => {
            const { status, message, data } =
                await fetchDashboardStatisticsCount();
            if (status !== 200) {
                toast.error(message);
                return;
            }

            setDashboardCountData({
                ...dashboardCountData,
                total_articles: data.drafts + data.publishedArticles,
                published_articles: data.publishedArticles,
                saved_templates: data.savedTemplates,
                drafts: data.drafts,
            });

            setSkeletonState((prevData) => {
                const payload = {
                    ...prevData,
                    account_stats: true,
                };

                return payload;
            });
        };

        getDashboardStatisticsCount();
        getTotalArticlesChartData(new Date().getFullYear());
        triggerFetchForActivityLogs();
    }, []);

    if (!userData.user.isPlanActive && !userData.user.isPlanModalShown) {
        document.getElementById('publish-modal')?.classList.remove('hidden');
    }

    return (
        <Layout>
            <div className="mb-4 flex flex-row justify-between">
                <h4 className="text-md dark:text-white">Dashboard</h4>
                <h6 className="text-xs dark:text-white">
                    {new Date().toDateString()}
                </h6>
            </div>
            <hr />
            <div className="flex flex-col gap-2 md:space-y-0 mt-2 lg:flex-row justify-between">
                <div className="w-full lg:w-3/4">
                    <div className="bg-white mb-4 rounded-lg dark:bg-gray-900">
                        <h4 className="px-6 py-4 text-md dark:text-white">
                            Account Statistics
                            <svg
                                className="mt-2"
                                xmlns="http://www.w3.org/2000/svg"
                                width="48"
                                height="4"
                                viewBox="0 0 48 4"
                                fill="none"
                            >
                                <path
                                    d="M0 2H48"
                                    stroke="#1565D8"
                                    strokeWidth="3"
                                ></path>
                            </svg>
                        </h4>
                        {!skeletonState.account_stats ? (
                            <div className="mt-4 px-6 pb-4">
                                <Skeleton count={4} />
                            </div>
                        ) : (
                            <div className="flex flex-col md:flex-row justify-between px-6 py-4">
                                <div className="flex gap-2 p-4 rounded-lg">
                                    <svg
                                        className="mt-2 dark:text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="1em"
                                        viewBox="0 0 512 512"
                                        fill="currentColor"
                                    >
                                        <path d="M96 96c0-35.3 28.7-64 64-64H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H80c-44.2 0-80-35.8-80-80V128c0-17.7 14.3-32 32-32s32 14.3 32 32V400c0 8.8 7.2 16 16 16s16-7.2 16-16V96zm64 24v80c0 13.3 10.7 24 24 24H296c13.3 0 24-10.7 24-24V120c0-13.3-10.7-24-24-24H184c-13.3 0-24 10.7-24 24zm208-8c0 8.8 7.2 16 16 16h48c8.8 0 16-7.2 16-16s-7.2-16-16-16H384c-8.8 0-16 7.2-16 16zm0 96c0 8.8 7.2 16 16 16h48c8.8 0 16-7.2 16-16s-7.2-16-16-16H384c-8.8 0-16 7.2-16 16zM160 304c0 8.8 7.2 16 16 16H432c8.8 0 16-7.2 16-16s-7.2-16-16-16H176c-8.8 0-16 7.2-16 16zm0 96c0 8.8 7.2 16 16 16H432c8.8 0 16-7.2 16-16s-7.2-16-16-16H176c-8.8 0-16 7.2-16 16z" />
                                    </svg>
                                    <div className="dark:text-white ml-2">
                                        <strong className="block text-xl">
                                            {dashboardCountData.total_articles}
                                        </strong>
                                        <h6 className="mt-2 text-sm">
                                            Total Articles
                                        </h6>
                                    </div>
                                </div>
                                <div className="flex gap-2 p-4 rounded-lg">
                                    <svg
                                        className="mt-2 dark:text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="1em"
                                        viewBox="0 0 448 512"
                                        fill="currentColor"
                                    >
                                        <path d="M96 0C43 0 0 43 0 96V416c0 53 43 96 96 96H384h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V384c17.7 0 32-14.3 32-32V32c0-17.7-14.3-32-32-32H384 96zm0 384H352v64H96c-17.7 0-32-14.3-32-32s14.3-32 32-32zm32-240c0-8.8 7.2-16 16-16H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16zm16 48H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16s7.2-16 16-16z" />
                                    </svg>
                                    <div className="dark:text-white ml-2">
                                        <strong className="block text-xl">
                                            {
                                                dashboardCountData.published_articles
                                            }
                                        </strong>
                                        <h6 className="mt-2 text-sm">
                                            Published Articles
                                        </h6>
                                    </div>
                                </div>
                                <div className="flex gap-2 p-4 rounded-lg">
                                    <svg
                                        className="mt-2 dark:text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="16"
                                        width="12"
                                        viewBox="0 0 384 512"
                                        fill="currentColor"
                                    >
                                        <path d="M0 48V487.7C0 501.1 10.9 512 24.3 512c5 0 9.9-1.5 14-4.4L192 400 345.7 507.6c4.1 2.9 9 4.4 14 4.4c13.4 0 24.3-10.9 24.3-24.3V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48z" />
                                    </svg>
                                    <div className="dark:text-white ml-2">
                                        <strong className="block text-xl">
                                            {dashboardCountData.saved_templates}
                                        </strong>
                                        <h6 className="mt-2 text-sm">
                                            Saved Templates
                                        </h6>
                                    </div>
                                </div>
                                <div className="flex gap-2 p-4 rounded-lg">
                                    <svg
                                        className="mt-2 dark:text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="1em"
                                        viewBox="0 0 576 512"
                                        fill="currentColor"
                                    >
                                        <path d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V285.7l-86.8 86.8c-10.3 10.3-17.5 23.1-21 37.2l-18.7 74.9c-2.3 9.2-1.8 18.8 1.3 27.5H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128zM549.8 235.7l14.4 14.4c15.6 15.6 15.6 40.9 0 56.6l-29.4 29.4-71-71 29.4-29.4c15.6-15.6 40.9-15.6 56.6 0zM311.9 417L441.1 287.8l71 71L382.9 487.9c-4.1 4.1-9.2 7-14.9 8.4l-60.1 15c-5.5 1.4-11.2-.2-15.2-4.2s-5.6-9.7-4.2-15.2l15-60.1c1.4-5.6 4.3-10.8 8.4-14.9z" />
                                    </svg>
                                    <div className="dark:text-white ml-2">
                                        <strong className="block text-xl">
                                            {dashboardCountData.drafts}
                                        </strong>
                                        <h6 className="mt-2 text-sm">
                                            My Drafts
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="bg-white mb-2 rounded-lg dark:bg-gray-900">
                        <Datachart
                            categoriesData={totalArticlesChartData.months}
                            seriesData={totalArticlesChartData.count}
                        />
                    </div>
                </div>
                <div className="w-full lg:w-1/4">
                    <div className="bg-white mb-2 rounded-lg dark:bg-gray-900">
                        <h4 className="px-6 py-4 text-sm text-center dark:text-white">
                            Docwrite template of the week
                        </h4>
                        <div className="flex flex-col items-center justify-center px-6 py-4">
                            <img
                                src={require('../../assets/dashboard/article-icon.png')}
                                alt="Popular template"
                                width={100}
                            />
                            <p className="mt-2 text-sm dark:text-gray-100">
                                How-To-Guides
                            </p>
                            <div className="flex flex-row mt-6">
                                <Button
                                    classValue="inline-flex items-center justify-center px-2 shadow text-sm font-semibold leading-6 text-white transition duration-150 ease-in-out rounded-md bg-brand p-2 w-full h-10 rounded-md text-white text-lg font-bold focus:border border-gray-700"
                                    label="Use Template"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="bg-white mb-2 rounded-lg dark:bg-gray-900">
                        <div className="px-6 py-2">
                            <div className="mt-2">
                                <div className="flex justify-between pt-4">
                                    <div>
                                        <h4 className="text-md font-bold mb-2 dark:text-white">
                                            Activity Log
                                        </h4>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="48"
                                            height="4"
                                            viewBox="0 0 48 4"
                                            fill="none"
                                        >
                                            <path
                                                d="M0 2H48"
                                                stroke="#1565D8"
                                                strokeWidth="3"
                                            ></path>
                                        </svg>
                                    </div>
                                    <div>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="25"
                                            viewBox="0 0 24 25"
                                            fill="none"
                                        >
                                            <rect
                                                y="0.494141"
                                                width="24"
                                                height="24"
                                                rx="4"
                                                fill="#8692A6"
                                                fillOpacity="0.1"
                                            ></rect>
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M12.0007 19.0005C12.734 19.0005 13.334 18.4005 13.334 17.6672H10.6674C10.6674 18.4005 11.2607 19.0005 12.0007 19.0005ZM16.0005 15V11.6667C16.0005 9.62 14.9072 7.90667 13.0005 7.45333V7C13.0005 6.44667 12.5538 6 12.0005 6C11.4472 6 11.0005 6.44667 11.0005 7V7.45333C9.08718 7.90667 8.00051 9.61333 8.00051 11.6667V15L6.66718 16.3333V17H17.3338V16.3333L16.0005 15Z"
                                                fill="#1565D8"
                                            ></path>
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M16.6667 11.9997C18.1394 11.9997 19.3333 10.8058 19.3333 9.33305C19.3333 7.86029 18.1394 6.66638 16.6667 6.66638C15.1939 6.66638 14 7.86029 14 9.33305C14 10.8058 15.1939 11.9997 16.6667 11.9997Z"
                                                fill="#FF8080"
                                                stroke="white"
                                                strokeWidth="1.2"
                                            ></path>
                                        </svg>
                                    </div>
                                </div>
                                {!skeletonState.activity_log ? (
                                    <div className="mt-4">
                                        <Skeleton count={14} />
                                    </div>
                                ) : (
                                    <div
                                        className="pt-4"
                                        style={{
                                            height: '36vh',
                                            overflowX: 'hidden',
                                            overflowY: 'auto',
                                        }}
                                    >
                                        {activityLogs.length > 0 ? (
                                            activityLogs.map((item: any) => {
                                                return (
                                                    <div
                                                        className="flex gap-4 pt-2"
                                                        key={item.id}
                                                    >
                                                        <img
                                                            src={handleRandomImageRetrieval()}
                                                        />
                                                        <h6 className="text-sm pt-2 dark:text-white">
                                                            {item.activity}
                                                        </h6>
                                                    </div>
                                                );
                                            })
                                        ) : (
                                            <p className="mt-12 text-center text-sm">
                                                No activities on your account
                                                currently...
                                            </p>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* Plan reminder modal */}
            <div className="fixed z-10 overflow-y-auto top-0 w-full left-0 hidden" id="publish-modal">
                <div className="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div className="fixed inset-0 transition-opacity">
                        <div className="absolute inset-0 bg-gray-900 opacity-75" />
                    </div>
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
                        &#8203;
                    </span>
                    <div
                        className="inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                        role="dialog"
                        aria-modal="true"
                        aria-labelledby="modal-headline"
                    >
                        <div className="mb-8 mr-4 cursor-pointer">
                            <svg
                                className="cursor-pointer mt-4 float-right"
                                xmlns="http://www.w3.org/2000/svg"
                                height="20"
                                width="20"
                                viewBox="0 0 384 512"
                            >
                                <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                            </svg>
                        </div>
                        <div className="bg-white pt-2 pb-4 sm:p-6 sm:pb-4 text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className='w-16 h-16 mx-auto mt-4'>
                                <path fill='rgb(59 130 246)' d="M224 0c-17.7 0-32 14.3-32 32V51.2C119 66 64 130.6 64 208v25.4c0 45.4-15.5 89.5-43.8 124.9L5.3 377c-5.8 7.2-6.9 17.1-2.9 25.4S14.8 416 24 416H424c9.2 0 17.6-5.3 21.6-13.6s2.9-18.2-2.9-25.4l-14.9-18.6C399.5 322.9 384 278.8 384 233.4V208c0-77.4-55-142-128-156.8V32c0-17.7-14.3-32-32-32zm0 96c61.9 0 112 50.1 112 112v25.4c0 47.9 13.9 94.6 39.7 134.6H72.3C98.1 328 112 281.3 112 233.4V208c0-61.9 50.1-112 112-112zm64 352H224 160c0 17 6.7 33.3 18.7 45.3s28.3 18.7 45.3 18.7s33.3-6.7 45.3-18.7s18.7-28.3 18.7-45.3z"/>
                            </svg>
                            <h3 className='mb-4 mt-4'>
                                <strong>Your Subscription Plan is expired</strong>
                            </h3>
                            <small>You currently do not have any active <strong>{userData.user.accountPlan}</strong> plan. You will be unable to access all benefits which your previous subscription plan carries.</small>
                            <br /><br />
                            <small>If you wish to continue using your plan, You can go ahead to subscribe for your current plan or an upgrade.</small>
                            <div className="mt-8 space-x-4">
                                <Button
                                    classValue="inline-flex items-center justify-center px-4 py-2 text-sm font-semibold leading-6 text-white transition duration-150 ease-in-out rounded-md shadow bg-red-500 p-2 h-12 rounded-md text-white text-lg font-bold focus:border border-gray-700"
                                    label="Close"
                                    onClick={closeModal}
                                />
                                <Button
                                    classValue="inline-flex items-center justify-center px-4 py-2 text-sm font-semibold leading-6 text-white transition duration-150 ease-in-out rounded-md shadow bg-blue-500 p-2 h-12 rounded-md text-white text-lg font-bold focus:border border-gray-700"
                                    label="Subscribe Now"
                                    onClick={() => alert("Subscribe feature incoming")}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export const getTimeDifferenceString = (givenDateString: string) => {
    var givenDate: any = new Date(givenDateString);
    var currentDate: any = new Date();
    var timeDifference = currentDate - givenDate;
    var secondsDifference = timeDifference / 1000;
    var minutesDifference = secondsDifference / 60;
    var hoursDifference = minutesDifference / 60;
    var daysDifference = hoursDifference / 24;
    var monthsDifference = currentDate.getMonth() - givenDate.getMonth() +
                          (12 * (currentDate.getFullYear() - givenDate.getFullYear()));
    var yearsDifference = currentDate.getFullYear() - givenDate.getFullYear();
  
    if (yearsDifference >= 1) {
      return Math.round(yearsDifference) + (Math.round(yearsDifference) === 1 ? " year ago" : " years ago");
    } else if (monthsDifference >= 1) {
      return Math.round(monthsDifference) + (Math.round(monthsDifference) === 1 ? " month ago" : " months ago");
    } else if (daysDifference >= 1) {
      return Math.round(daysDifference) + (Math.round(daysDifference) === 1 ? " day ago" : " days ago");
    } else if (hoursDifference >= 1) {
      return Math.round(hoursDifference) + (Math.round(hoursDifference) === 1 ? " hour ago" : " hours ago");
    } else if (minutesDifference >= 1) {
      return Math.round(minutesDifference) + (Math.round(minutesDifference) === 1 ? " minute ago" : " minutes ago");
    } else {
      return Math.round(secondsDifference) + (Math.round(secondsDifference) === 1 ? " second ago" : " seconds ago");
    }
}
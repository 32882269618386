import { Axios } from "../helpers/axiosHelper";

let status: number;
let message: string;
let data: any;

export const requestPasswordResetLink = async (payload: any) => {
    try {
        const response = await Axios({
            url: '/account/forgot-password', 
            method: 'post', 
            body: payload,
        });
        status = 200;
        message = response.message;
        data = response.data;
    } catch (err: any) {
        status = err.response.status;
        message = err.response.data.message;
    }
    return { status, message, data };
}

export const verifyPasswordResetLink = async (payload: any) => {
    try {
        const response = await Axios({
            url: payload.url, 
            method: 'get', 
        });
        status = 200;
        message = response.message;
        data = response.data;
    } catch (err: any) {
        status = err.response.status;
        message = err.response.data.message;
    }
    return { status, message, data };
}

export const resetPassword = async (payload: any) => {
    try {
        const response = await Axios({
            url: '/account/reset-password', 
            method: 'patch',
            body: payload,
        });
        status = 200;
        message = response.message;
        data = response.data;
    } catch (err: any) {
        status = err.response.status;
        message = err.response.data.message;
    }
    return { status, message, data };
}

export const logout = async () => {
    try {
        const response = await Axios({
            url: '/auth/logout', 
            method: 'get',
        });
        status = 200;
        message = response.message;
    } catch (err: any) {
        status = err.response.status;
        message = err.response.data.message;
    }
    return { status, message, data };
}

export const fetchUserActivityLogs = async () => {
    try {
        const response = await Axios({
            url: '/account/activity-logs', 
            method: 'get',
        });
        status = 200;
        message = response.message;
        data = response.data;
    } catch (err: any) {
        status = err.response.status;
        message = err.response.data.message;
    }
    return { status, message, data };
}
// External imports
import { ReactNode } from "react";

// Internal imports
import "../main/layout.css";
import EditorLayoutHeader from "./EditorLayoutHeader";
import EditorLayoutSidebar from "./EditorLayoutSidebar";
import Footer from "../Footer";

const EditorLayout = ({children}: {children: ReactNode}) => {
    return (
        <section
            className="flex bg-gray-100 w-full min-h-screen"
            x-data="{panel:false, menu:true}"
        >
            <EditorLayoutSidebar />
            <div className="flex-grow text-gray-800">
                <EditorLayoutHeader />
                <main className="p-2 sm:p-10 space-y-2">
                    <section className="font-semibold text-gray-500">
                        {/* Page content section */}
                        {children}
                        {/* End of page content section */}
                    </section>
                </main>
                <Footer type="editor" />
            </div>
        </section>
    )
}

export default EditorLayout;
import { Axios } from "../helpers/axiosHelper";

let status: number;
let message: string;
let data: any;

export const fetchDashboardStatisticsCount = async () => {
    try {
        const response = await Axios({
            url: '/statistics/dashboard-counts', 
            method: 'get',
        });
        status = 200;
        message = response.message;
        data = response.data;
    } catch (err: any) {
        status = err.response.status;
        message = err.response.data.message;
    }
    return { status, message, data };
}

export const fetchGraphicalDataForTotalArticles = async (year: number) => {
    try {
        const response = await Axios({
            url: `/statistics/total-articles?year=${year}`, 
            method: 'get',
        });
        status = 200;
        message = response.message;
        data = response.data;
    } catch (err: any) {
        status = err.response.status;
        message = err.response.data.message;
    }
    return { status, message, data };
}
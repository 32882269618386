// External imports
import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import editorJsHtml from 'editorjs-html';

// Internal imports
import EditorLayout from "../../components/layout/editor/EditorLayout";
import EditorPlayground from "../../components/layout/editor/EditorPlayground";
import ArticlePreview from "../../components/dataDisplay/ArticlePreview";
import { fetchArticleData } from "../../services/article";
import { checklistParser, codeParser } from "../../helpers/templateHelper";

const has = Object.prototype.hasOwnProperty;

const EditorJsToHtml = editorJsHtml({ 
    checklist: checklistParser,
    code: codeParser
});
let html:any = [];

const CreateArticle = () => {
    const params = useParams();

    const draftData = useRef<any>();
    
    if (draftData.current && has.call(draftData.current, 'time')) {
        html = EditorJsToHtml.parse(draftData.current);
        
    }

    const [isEditMode, setIsEditMode] = useState(true);
    const [articleData, setArticleData] = useState({
        title: '',
        subtitle: '',
        coverImageUrl: '',
    });

    const fetchArticle = async () => {
        if (params.id) {
            const {status, data  } = await fetchArticleData(params.id);
            if (status === 200) {
                const { title, subtitle, coverImageUrl, content } = data;
                draftData.current = content;
        
                setArticleData({
                    ...articleData,
                    title,
                    subtitle,
                    coverImageUrl,
                })
                setIsEditMode(false);
            }

            return false;
        }
    }

    return (
        <EditorLayout>
            <div className="iframe_wrapper" style={{width: '96%', marginLeft: 'auto', marginRight: 'auto', marginBottom: '15px'}}>
                <div className="relative flex items-center justify-center flex-none px-4 bg-gray-900 rounded-b-none w-full rounded-xl">
                    <div className="flex space-x-1.5 mr-auto">
                        <div className="w-2 h-2 border-2 border-red-500 rounded-full"></div>
                        <div className="w-2 h-2 border-2 border-yellow-400 rounded-full"></div>
                        <div className="w-2 h-2 border-2 border-green-400 rounded-full"></div>
                    </div>
                    <div className="!flex items-center py-3 px-1">
                        <button className="sc-knuQbY gihsAp bg-gray-800"></button>
                        <div className="flex items-center px-1 py-1.5 rounded-xl bg-gray-700">
                            <button className={`flex items-center px-2 py-0.5 ml-2 text-gray-300 rounded-xl ${isEditMode ? 'bg-gray-500' : 'bg-none'} !text-white`} onClick={() => setIsEditMode(true)}>
                                <svg className="w-4 h-4 mr-1 " xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none">
                                    <path d="M7 8L3 11.6923L7 16M17 8L21 11.6923L17 16M14 4L10 20" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg>
                                <span className="text-xs">Edit</span>
                            </button>
                            <button className={`flex items-center px-2 py-0.5 ml-2 text-gray-300 rounded-xl ${!isEditMode ? 'bg-gray-500' : 'bg-none'}`} onClick={() => fetchArticle()}>
                                <svg className="w-4 h-4 py-0.5 mr-1 text-blue-400" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="32px" height="24px" viewBox="0 0 576 512" version="1.1">
                                    <path d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z"/>
                                </svg>
                                <span className="text-xs">Preview</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="w-full flex-1 relative mb-2 lg:h-[68vh]">
                    <iframe className="iframe w-full h-full" id="viewIframe" scrolling="yes"></iframe>
                    <div className="absolute top-0 w-full h-[70vh] border border-gray-400 overflow-y-auto">
                        {
                            isEditMode ? <EditorPlayground /> : <ArticlePreview articleData={articleData} html={html} />
                        }
                    </div>
                </div>
            </div>
        </EditorLayout>
    )
}

export default CreateArticle;
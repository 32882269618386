import { useNavigate, useParams } from "react-router-dom";
import AppModeSwitcher from "../AppModeSwitcher";
import { exportArticleToMarkdown } from "../../../services/article";

const EditorLayoutHeader = () => {
    const params = useParams();
    const navigate = useNavigate();

    const handleArticleExport = async (fileType: string) => {
        if (params.id) {
            const { data } = await exportArticleToMarkdown(params.id);
            const link = document.createElement('a')

            link.setAttribute('href', 'data:text/markdown;charset=utf-8,' + encodeURIComponent(data))
            link.setAttribute('download', 'article.md')
            link.style.display = 'none'

            document.body.appendChild(link)

            link.click()

            document.body.removeChild(link)
        }
    }

    return (
        <header className="flex items-center h-17 py-4 px-6 sm:px-10 bg-white border-b border-gray-200 dark:bg-gray-900 dark:border-b-gray-400">
            <div className="flex flex-shrink-0 items-center ml-auto">
                <div className="pr-1 space-x-1">
                    <button className="relative p-2 border border-gray-400 bg-gray-200 text-gray-400 hover:bg-gray-100 hover:text-gray-600 focus:border-2 focus:bg-gray-100 focus:text-gray-600 rounded-md dark:bg-gray-600 dark:border-gray-600">
                        <svg className="h-3 w-3 text-gray-600 dark:text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor">
                            <path d="M48.5 224H40c-13.3 0-24-10.7-24-24V72c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2L98.6 96.6c87.6-86.5 228.7-86.2 315.8 1c87.5 87.5 87.5 229.3 0 316.8s-229.3 87.5-316.8 0c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0c62.5 62.5 163.8 62.5 226.3 0s62.5-163.8 0-226.3c-62.2-62.2-162.7-62.5-225.3-1L185 183c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8H48.5z"/>
                        </svg>
                    </button>
                    <button className="relative p-2 border border-gray-400 bg-gray-200 text-gray-400 hover:bg-gray-100 hover:text-gray-600 focus:border-2 focus:bg-gray-100 focus:text-gray-600 rounded-md dark:bg-gray-600 dark:border-gray-600">
                        <svg className="h-3 w-3 text-gray-600 dark:text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor">
                            <path d="M463.5 224H472c13.3 0 24-10.7 24-24V72c0-9.7-5.8-18.5-14.8-22.2s-19.3-1.7-26.2 5.2L413.4 96.6c-87.6-86.5-228.7-86.2-315.8 1c-87.5 87.5-87.5 229.3 0 316.8s229.3 87.5 316.8 0c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0c-62.5 62.5-163.8 62.5-226.3 0s-62.5-163.8 0-226.3c62.2-62.2 162.7-62.5 225.3-1L327 183c-6.9 6.9-8.9 17.2-5.2 26.2s12.5 14.8 22.2 14.8H463.5z"/>
                        </svg>
                    </button>
                </div>
                <div className="border-r pr-3 ml-3 space-x-1">
                    <button className="relative p-2 border border-gray-400 bg-gray-200 text-gray-400 hover:bg-gray-100 hover:text-gray-600 focus:border-2 focus:bg-gray-100 focus:text-gray-600 rounded-md dark:bg-gray-600 dark:border-gray-600" onClick={() => window.location.reload()}>
                        <svg className="h-3 w-3 text-gray-600 dark:text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor">
                            <path d="M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160H352c-17.7 0-32 14.3-32 32s14.3 32 32 32H463.5c0 0 0 0 0 0h.4c17.7 0 32-14.3 32-32V80c0-17.7-14.3-32-32-32s-32 14.3-32 32v35.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1V432c0 17.7 14.3 32 32 32s32-14.3 32-32V396.9l17.6 17.5 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352H160c17.7 0 32-14.3 32-32s-14.3-32-32-32H48.4c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z"/>
                        </svg>
                    </button>
                </div>
                <div className="flex border-r pr-3 ml-3 space-x-1">
                    <div className='dropdown inline-block relative'>
                        <button className="flex items-center space-x-2 p-2 inline-flex items-center justify-center px-4 py-2 text-sm font-semibold leading-6 text-white transition duration-150 ease-in-out rounded-md shadow bg-blue-500 p-2 h-6 rounded-md text-white text-lg font-bold focus:border border-blue-500">
                            <svg className="h-3 w-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" stroke="currentColor" fill="white">
                                <path d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V288H216c-13.3 0-24 10.7-24 24s10.7 24 24 24H384V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64zM384 336V288H494.1l-39-39c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l80 80c9.4 9.4 9.4 24.6 0 33.9l-80 80c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l39-39H384zm0-208H256V0L384 128z"/>
                            </svg>
                            <span className="text-xs">Export</span>
                        </button>
                        <ul className="dropdown-menu absolute hidden text-gray-700 pt-1 w-full z-40 bg-white border rounded-md">
                            <li className="cursor-pointer" onClick={() => handleArticleExport('markdown')}><span className="hover:bg-gray-200 text-gray-600 rounded py-2 px-4 block whitespace-no-wrap text-sm">Markdown</span></li>
                            <li className="cursor-pointer" onClick={() => handleArticleExport('html')}><span className="hover:bg-gray-200 rounded py-2 px-4 text-gray-600 block whitespace-no-wrap text-sm">HTML</span></li>
                        </ul>
                    </div>
                </div>
                <div className="space-x-1">
                    <AppModeSwitcher />
                </div>
            </div>
        </header>
    )
}

export default EditorLayoutHeader;
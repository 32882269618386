import axios from "axios";
import { LocalStorage } from "./localStorageHelper";

interface AxiosOptions {
    url: string;
    method: "get" | "post" | "put" | "delete" | "patch";
    body?: object | null;
    headers?: object | undefined;
}

axios.defaults.baseURL =
    process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_API_URL_DEV
        : process.env.REACT_APP_API_URL_PROD;
axios.defaults.withCredentials = true;
axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response.status === 401) {
            // call api to get new refresh token
            refreshTokenCall();
        }
        return Promise.reject(error);
    }
);

const refreshTokenCall = async () => {
    try {
        localStorage.setItem("redirect_url", window.location.href);

        await Axios({
            url: "/auth/request-access-token",
            method: "post",
            body: {
                refreshToken: LocalStorage.getItem("refresh_token"),
            },
        });

        const redirectUrl = localStorage.getItem("redirect_url");
        window.location.href = redirectUrl || "/dashboard";
    } catch (err) {
        window.location.href = "/";
    }
};

export const Axios = async ({ url, method, body, headers }: AxiosOptions) => {
    const res = await axios({
        method: method,
        url: url,
        data: body,
        headers: headers,
    });
    return res.data;
};

// External imports
import { ChangeEvent, ChangeEventHandler, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

// Internal imports
import Logo from '../../assets/logo.svg';
import VectorIconBottom from '../../assets/onboarding/bg-vector-bottom.svg';
import vectorIconTop from '../../assets/onboarding/bg-vector-top.svg';
import TextField from '../../components/form/TextField';
import Button from '../../components/form/Button';
import Toastify from '../../components/feedback/Alert';
import { PasswordResetProps } from '../../interfaces/account';
import { passwordResetValidationSchema } from '../../validators/account';
import { LocalStorage } from '../../helpers/localStorageHelper';
import { resetPassword } from '../../services/account';

export default function PasswordReset() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormdata] = useState<PasswordResetProps>({
        password: '',
        confirmPassword: '',
    });

    // Bootstrap react hook form validation
    const { register, handleSubmit, formState: { errors } } = useForm <PasswordResetProps>({
        resolver: yupResolver(passwordResetValidationSchema)
    });

    // Update form values with user typed values
    const handleChange: ChangeEventHandler<HTMLInputElement> = (event: ChangeEvent<HTMLInputElement>) => {
        setFormdata({
            ...formData,
            [event.target.name]: event.target.value,
        });
    }

    // Function to submit form
    const onSubmit = async (formData: PasswordResetProps) => {
        setIsLoading(true);
        if (formData.password !== formData.confirmPassword) {
            toast.error('Password and Confirm password do not match');
            setIsLoading(false);
            return;
        }

        // Call API to reset password
        try {
            const payload = {
                password: formData.password,
                token: LocalStorage.getItem('reset-token')
            }
            const { status, message } = await resetPassword(payload);
            if (status !== 200) {
                toast.error(message);
                setIsLoading(false);
                return;
            }
            
            toast.success(message);
            setIsLoading(false);
            LocalStorage.removeItem('reset-token');
            setTimeout(() => {
                navigate('/');
            }, 2000);
        } catch (err) {
            toast.error('Unable to process forgot password');
            setIsLoading(false);
            return;
        }
    };

    return (
        <>
            <div className="bg-gradient-to-r from-blue-100 to-blue-100 bg-opacity-10 h-screen">
                <img src={vectorIconTop} alt='Vector top' className="absolute top-0 right-0 float-right" />
                <div className="bg-cover bg-no-repeat bg-center" style={{ backgroundImage: `url('../../assets/onboarding/bg.png')` }}>
                    <div className="px-4 lg:px-8 py-4">
                        <div className='mt-4'>
                            <img src={Logo} alt='Logo' width={100} height={100} />
                        </div>
                        <div className='md:mx-80 mt-6 lg:mt-8 p-6 bg-white rounded-lg'>
                            <h3 className='text-center font-bold text-xl'>Reset Your Password</h3>
                            <div className='mt-8 mb-4 px-1 lg:px-4'>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                <div className='grid grid-cols-1 mt-6'>
                                        <div>
                                            <p className='mb-2 font-bold text-xs lg:text-md'>Password</p>
                                            <TextField type='password' name='password' placeholder='**********' register={register} errors={errors} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className='grid grid-cols-1 mt-6'>
                                        <div>
                                            <p className='mb-2 font-bold text-xs lg:text-md'>Confirm Password</p>
                                            <TextField type='password' name='confirmPassword' placeholder='**********' register={register} errors={errors} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className='mt-8'>
                                        <Button classValue='inline-flex items-center justify-center px-4 py-2 text-sm font-semibold leading-6 text-white transition duration-150 ease-in-out rounded-md shadow bg-brand p-2 w-full h-12 rounded-md text-white text-lg font-bold focus:border border-gray-700' loading={isLoading} label='Reset Password' />
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className='mt-4'>
                            <p className='text-center text-sm'>Already have an account? <a href='/' className="text-brand font-bold">Login</a></p>
                        </div>
                    </div>
                </div>
                <img src={VectorIconBottom} alt='Vector bottom' className="absolute bottom-0 left-0 float-right" />
            </div>
            <Toastify />
        </>
    )
}
import { Axios } from "../helpers/axiosHelper";
import { RegisterProps, LoginProps } from "../interfaces/onboarding";

let status: number;
let message: string;
let data: any;

export const registerUser = async (payload: RegisterProps) => {
    try {
        const response = await Axios({
            url: '/auth/register', 
            method: 'post', 
            body: payload,
        });
        status = 200;
        message = response.message;
    } catch (err: any) {
        status = err.response.status;
        message = err.response.data.message;
    }
    return { status, message };
}

export const signInUser = async (payload: LoginProps) => {
    try {
        const response = await Axios({
            url: '/auth/login', 
            method: 'post', 
            body: payload,
        });
        status = 200;
        message = response.message;
        data = response.data;
    } catch (err: any) {
        status = err.response.status;
        message = err.response.data.message;
    }
    return { status, message, data };
}

export const initiateSsoSignup = async () => {
    try {
        const response = await Axios({
            url: '/auth/signin-google', 
            method: 'post', 
        });
        status = 200;
        message = response.message;
        data = response.data;
    } catch (err: any) {
        status = err.response.status;
        message = err.response.data.message;
    }
    return { status, message, data };
}
// External imports
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

// Internal imports
import Layout from '../../components/layout/main/Layout';
import TemplateCard from '../../components/dataDisplay/TemplateCard';
import apiDocsImage from '../../assets/dashboard/api-docs.png';
import howToGuidesImage from '../../assets/dashboard/how-to-guides.png';
import codeSamplesImage from '../../assets/dashboard/code-samples.png';
import caseStudy from '../../assets/dashboard/case-study.png';
import { fetchUserSavedTemplates } from '../../services/template';
import { getRandomColor } from '../../helpers/templateHelper';
import { getRandomImage } from '../../helpers/globalHeper';

export default function SavedTemplates() {
    const [templates, setTemplates] = useState([]);
    const [skeletonState, setSkeletonState] = useState({
        template_data_saved: false,
    });

    useEffect(() => {
        const getSavedTemplates = async () => {
            const { status, data } = await fetchUserSavedTemplates();
            if (status !== 200) {
                setTemplates([]);
                return;
            }

            setTemplates(data);
            setSkeletonState((prevData) => {
                const payload = {
                    ...prevData,
                    template_data_saved: true,
                };

                return payload;
            });
        };

        getSavedTemplates();
    }, []);

    const handleRandomImageRetrieval = () => {
        const data: string = getRandomImage([
            apiDocsImage,
            howToGuidesImage,
            codeSamplesImage,
            caseStudy,
        ]);
        return data;
    };

    return (
        <Layout>
            <div>
                <div className="mb-2 flex flex-row justify-between">
                    <h4 className="text-md text-black dark:text-gray-300">
                        Saved Templates
                    </h4>
                    <h6 className="text-xs dark:text-gray-300">
                        {new Date().toDateString()}
                    </h6>
                </div>
                <p className="mb-4 text-sm dark:text-gray-300">
                    Make use of your saved templates to create your next article
                </p>
            </div>

            <hr />
            <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mt-2">
                {!skeletonState.template_data_saved &&
                    [2, 3, 4, 5].map((data) => (
                        <div className="bg-white relative mb-2 cursor-pointer shadow-md rounded-lg overflow-hidden p-4">
                            <Skeleton count={8} />
                        </div>
                    ))}
            </div>
            {templates.length === 0 ? (
                <p className="text-center my-4">No template saved.</p>
            ) : (
                <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-4 mt-4">
                    {templates.map((template: any) => (
                        <TemplateCard
                            key={template.template_details.id}
                            title={template.template_details.name}
                            image={handleRandomImageRetrieval()}
                            action="use"
                            category={
                                template.template_details.article_category.name
                            }
                            color={getRandomColor()}
                            templateId={template.template_details.id}
                        />
                    ))}
                </div>
            )}
        </Layout>
    );
}

import './articlePreview.css';

export type ArticleDataProps = {
    coverImageUrl: string;
    title: string;
    subtitle: string;
    reference?: string;
};

type ArticlePreviewProps = {
    articleData: ArticleDataProps;
    html: any;
};

export default function ArticlePreview({
    articleData,
    html,
}: ArticlePreviewProps) {
    return (
        <>
            {
                articleData.reference && <div className='mb-4'>
                    Content Reference: {articleData.reference}
                </div>
            }
            <section className="bg-white min-h-full text-black">
                <div
                    className="w-article-section mr-auto ml-auto px-8 py-4"
                    key={2}
                >
                    <div className="text-center">
                        <div
                            id="cover-image"
                            className="mt-2"
                            style={{
                                backgroundImage: `${
                                    articleData.coverImageUrl
                                        ? `url(${articleData.coverImageUrl})`
                                        : ''
                                }`,
                                backgroundSize: `${
                                    articleData.coverImageUrl ? 'cover' : ''
                                }`,
                                position: `${
                                    articleData.coverImageUrl
                                        ? 'relative'
                                        : 'inherit'
                                }`,
                                height: `${
                                    articleData.coverImageUrl ? '35rem' : '0rem'
                                }`,
                                width: `${
                                    articleData.coverImageUrl ? '100%' : '0%'
                                }`,
                            }}
                        ></div>
                        <h1 className="mt-8 text-4xl">{articleData.title}</h1>
                        <h3 className="mt-8 text-2xl">{articleData.subtitle}</h3>
                    </div>
                    <div className="mt-8">
                        {html.map((item: string, index: number) => {
                            if (typeof item === 'string') {
                                return (
                                    <div
                                        dangerouslySetInnerHTML={{ __html: item }}
                                        key={index}
                                        className="mt-4 dynamic-element break-all"
                                    ></div>
                                );
                            }
                            return JSON.stringify(item);
                        })}
                    </div>
                </div>
            </section>
        </>
    );
}

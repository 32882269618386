import { SelectFieldProps } from '../../interfaces/forms';

const SelectField: React.FC<SelectFieldProps> = ({ data, name, register, defaultValue, errors, onChange }) => {
    return (
        <>
            <select name={name} value={defaultValue} {...register(name)} onChange={onChange} className='h-12 w-full border border-gray-200 rounded-md px-2'>
                <option>Select value</option>
                {
                    data.map((item: any, index) => {
                        return (
                            <option value={item.name} key={index + 1}>{item.name}</option>
                        )
                    })
                }
            </select>
            {errors[name] && <p className='text-red-400 text-sm mt-2'>{errors[name].message}</p>}
        </>
    )
}

export default SelectField;
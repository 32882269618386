// External imports
import React, { useEffect, useRef, useState } from 'react';
import EditorJS, { OutputData, ToolConstructable } from '@editorjs/editorjs';
import Header from '@editorjs/header';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

// Internal imports
import Button from '../../form/Button';
import { EDITOR_TOOLS } from '../../../components/Editor/EditorTool';
import { RootState } from '../../../store';
import {
    uploadCoverImage,
    updateArticle,
    removeCoverImage,
    fetchArticleData,
} from '../../../services/article';

let typingTimer: NodeJS.Timeout;
const delay = 1000;

const EditorPlayground = () => {
    const params = useParams();
    const userData = useSelector((state: RootState) => state.userData);

    const ejInstance = useRef<EditorJS>();
    const draftData = useRef<OutputData>();

    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isSavingCompleted, setIsSavingCompleted] = useState<boolean>(false);
    const [isTypingCompleted, setIsTypingCompleted] = useState<boolean>(false);
    const [textareaHeight, setTextareaHeight] = useState(0);
    const [isSubtitlePresent, setIsSubtitlePresent] = useState<boolean>(false);
    const [isCoverImagePresent, setIsCoverImagePresent] =
        useState<boolean>(false);
    const [imageUrlInput, setImageUrlInput] = useState<string>('');
    const [articleData, setArticleData] = useState({
        title: '',
        subtitle: '',
        coverImageUrl: '',
    });

    const initEditor = () => {
        const editor = new EditorJS({
            holder: 'editorjs',
            autofocus: true,
            placeholder: 'Start typing here...',
            tools: {
                ...EDITOR_TOOLS,
                header: {
                    class: Header as unknown as ToolConstructable,
                    inlineToolbar: ['link'],
                    config: {
                        placeholder: 'Enter a header',
                        levels: [1, 2, 3, 4, 5, 6],
                    },
                    shortcut: 'CMD+SHIFT+H',
                },
            },
            data: draftData.current,
            onReady: () => {
                ejInstance.current = editor;
                editor.caret.setToLastBlock('end');
            },
            onChange: async (api, event) => {
                setIsSaving(true);
                let content = await api.saver.save();
                draftData.current = content;
                setArticleData((prevData) => {
                    const payload = {
                        ...prevData,
                        content: content,
                        userId: userData.user.id,
                    };
                    if (params.id) {
                        updateArticle(payload, params.id)
                            .then((response) => {
                                const { status } = response;
                                if (status === 200) {
                                    setIsSaving(false);
                                    setIsSavingCompleted(true);
                                    setTimeout(() => {
                                        setIsSavingCompleted(false);
                                    }, 2000);
                                }
                            })
                            .catch((err) => {
                                toast.error('Unable to update data');
                            });
                    }
                    return payload;
                });
            },
            hideToolbar: false,
        });
    };

    // Fetch article and instantiate editor in DOM
    useEffect(() => {
        let timeOut: NodeJS.Timeout;
        const fetchArticle = async () => {
            if (params.id) {
                const { status, data } = await fetchArticleData(params.id);
                if (status === 200) {
                    const { title, subtitle, coverImageUrl, content } = data;

                    timeOut = setTimeout(() => {
                        const titleScrollHeight =
                            document.querySelector(
                                '.article-title'
                            )?.scrollHeight;
                        setTextareaHeight(titleScrollHeight!);
                    }, 1);

                    draftData.current = content;
                    setArticleData({
                        title: title,
                        subtitle: subtitle,
                        coverImageUrl: coverImageUrl,
                    });

                    if (subtitle) {
                        setIsSubtitlePresent(true);
                    }

                    if (coverImageUrl) {
                        setIsCoverImagePresent(true);
                    }

                    if (!ejInstance.current) {
                        initEditor();
                    }

                    return () => {
                        ejInstance?.current?.destroy();
                        ejInstance.current = undefined;
                    };
                }

                return false;
            }
        };

        fetchArticle();
        return () => {
            clearTimeout(timeOut);
        };
    }, [params]);

    // Implement modal toggling for image uploading
    useEffect(() => {
        let tabTogglers = document
            .querySelector('#tabs')
            ?.querySelectorAll('#tabs a');
        tabTogglers?.forEach(function (toggler) {
            toggler.addEventListener('click', (e) => {
                e.preventDefault();
                let tabContents = document.querySelector('#tab-contents');
                if (tabContents) {
                    for (let i = 0; i < tabContents.children.length; i++) {
                        if (tabTogglers && tabTogglers[i]) {
                            const togglerVal =
                                tabTogglers[i].parentElement?.classList;
                            if (togglerVal?.contains('border-t')) {
                                togglerVal.remove(
                                    'border-t',
                                    'border-r',
                                    'border-l',
                                    '-mb-px',
                                    'bg-white'
                                );
                            }

                            tabContents.children[i].classList.remove('hidden');
                            if (
                                '#' + tabContents.children[i].id ===
                                toggler.getAttribute('href')
                            ) {
                                togglerVal?.add(
                                    'border-t',
                                    'border-r',
                                    'border-l',
                                    '-mb-px',
                                    'bg-white'
                                );
                                continue;
                            }
                            tabContents.children[i].classList.add('hidden');
                        }
                    }
                }
            });
        });
    }, []);

    useEffect(() => {
        clearTimeout(typingTimer);
        typingTimer = setTimeout(async () => {
            if (isTypingCompleted && params.id) {
                setIsSaving(true);
                const { status } = await updateArticle(
                    { ...articleData, content: draftData.current },
                    params.id
                );
                if (status === 200) {
                    setIsSaving(false);
                    setIsSavingCompleted(true);
                    setTimeout(() => {
                        setIsSavingCompleted(false);
                    }, 2000);
                }

                setIsTypingCompleted(false);
            }
        }, delay);

        return () => {
            clearTimeout(typingTimer);
        };
    }, [articleData.title, articleData.subtitle]);

    const handleTextareaSize = (event: any) => {
        const { clientHeight, scrollHeight } = event.target;
        setTextareaHeight(scrollHeight);
    };

    const toggleModal = (id: string) => {
        document.getElementById(id)?.classList.toggle('hidden');
    };

    const toggleSubtitleField = () => {
        setIsSubtitlePresent(!isSubtitlePresent);
    };

    // Handle article cover image upload to server
    const handleCoverImageUpload = async (event: any) => {
        const formData = new FormData();
        formData.append('image', event.target.files[0]);
        if (params.id) {
            const { status, data } = await uploadCoverImage(
                formData,
                params.id
            );
            if (status === 200) {
                setArticleData({
                    ...articleData,
                    coverImageUrl: data,
                });
            }

            setIsCoverImagePresent(true);
            toggleModal('image-modal');
        }
    };

    // Handle cover image removal of article
    const handleImageRemoval = async () => {
        if (params.id) {
            if (window.confirm('Are you sure you want to remove image?')) {
                const { status } = await removeCoverImage(params.id);
                if (status === 200) {
                    setIsCoverImagePresent(false);
                    setArticleData({
                        ...articleData,
                        coverImageUrl: '',
                    });
                    return;
                }
            } else {
                return false;
            }
        }
    };

    const handleUrlImageUpload = async () => {
        if (params.id) {
            const { status } = await updateArticle(
                {
                    ...articleData,
                    content: draftData.current,
                    coverImageUrl: imageUrlInput,
                },
                params.id
            );
            if (status === 200) {
                setIsSaving(false);
                setArticleData({
                    ...articleData,
                    coverImageUrl: imageUrlInput,
                });
                toggleModal('image-modal');

                setIsSavingCompleted(true);
                setTimeout(() => {
                    setIsSavingCompleted(false);
                }, 2000);
            }
        }
    };

    return (
        <>
            <section>
                <div className="text-center py-2 px-2">
                    {isSavingCompleted && (
                        <span className="inline-flex flex-row gap-2 text-green-600 font-bold">
                            <svg
                                className="mt-1 text-green"
                                xmlns="http://www.w3.org/2000/svg"
                                height="16"
                                width="18"
                                viewBox="0 0 576 512"
                            >
                                <path
                                    d="M96 80c0-26.5 21.5-48 48-48H432c26.5 0 48 21.5 48 48V384H96V80zm313 47c-9.4-9.4-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L409 161c9.4-9.4 9.4-24.6 0-33.9zM0 336c0-26.5 21.5-48 48-48H64V416H512V288h16c26.5 0 48 21.5 48 48v96c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V336z"
                                    fill="green"
                                />
                            </svg>
                            Saved
                        </span>
                    )}
                    {isSaving && (
                        <span className="inline-flex flex-row gap-2 font-bold">
                            <svg
                                className="w-5 h-5 text-white animate-spin mt-1"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                            >
                                <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                ></circle>
                                <path
                                    className="opacity-75"
                                    fill="gray"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                            </svg>
                            Saving...
                        </span>
                    )}
                </div>
            </section>
            <section className="h-full">
                <div className="w-article-section mr-auto ml-auto">
                    <div className="flex flex-row gap-4">
                        {!isCoverImagePresent && (
                            <div
                                className="flex flex-row gap-2 mt-4 p-2 hover:bg-gray-100 hover:w-44 hover:rounded-2xl hover:cursor-pointer"
                                onClick={() => toggleModal('image-modal')}
                            >
                                <svg
                                    className="mt-1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="16"
                                    width="16"
                                    viewBox="0 0 512 512"
                                >
                                    <path d="M448 80c8.8 0 16 7.2 16 16V415.8l-5-6.5-136-176c-4.5-5.9-11.6-9.3-19-9.3s-14.4 3.4-19 9.3L202 340.7l-30.5-42.7C167 291.7 159.8 288 152 288s-15 3.7-19.5 10.1l-80 112L48 416.3l0-.3V96c0-8.8 7.2-16 16-16H448zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm80 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z" />
                                </svg>
                                <span className="text-gray-600">
                                    Add cover image
                                </span>
                            </div>
                        )}
                        {!isSubtitlePresent && (
                            <div
                                className="flex flex-row gap-2 mt-4 p-2 hover:bg-gray-100 hover:w-48 hover:rounded-2xl hover:cursor-pointer"
                                onClick={toggleSubtitleField}
                            >
                                <svg
                                    className="mt-1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="16"
                                    width="16"
                                    viewBox="0 0 512 512"
                                >
                                    <path d="M368.4 18.3L312.7 74.1 437.9 199.3l55.7-55.7c21.9-21.9 21.9-57.3 0-79.2L447.6 18.3c-21.9-21.9-57.3-21.9-79.2 0zM288 94.6l-9.2 2.8L134.7 140.6c-19.9 6-35.7 21.2-42.3 41L3.8 445.8c-3.8 11.3-1 23.9 7.3 32.4L164.7 324.7c-3-6.3-4.7-13.3-4.7-20.7c0-26.5 21.5-48 48-48s48 21.5 48 48s-21.5 48-48 48c-7.4 0-14.4-1.7-20.7-4.7L33.7 500.9c8.6 8.3 21.1 11.2 32.4 7.3l264.3-88.6c19.7-6.6 35-22.4 41-42.3l43.2-144.1 2.8-9.2L288 94.6z" />
                                </svg>
                                <span className="text-gray-600">
                                    Add article subtitle
                                </span>
                            </div>
                        )}
                    </div>
                    <div
                        id="cover-image"
                        className="mt-2 h-full"
                        style={{
                            backgroundImage: `${
                                articleData.coverImageUrl
                                    ? `url(${articleData.coverImageUrl})`
                                    : ''
                            }`,
                            backgroundSize: `${
                                articleData.coverImageUrl ? 'cover' : ''
                            }`,
                            position: `${
                                articleData.coverImageUrl
                                    ? 'relative'
                                    : 'inherit'
                            }`,
                            height: `${
                                articleData.coverImageUrl ? '35rem' : '0rem'
                            }`,
                            width: `${
                                articleData.coverImageUrl ? '100%' : '0%'
                            }`,
                        }}
                    >
                        {articleData.coverImageUrl && (
                            <div className="absolute top-0 right-0 left-0 mx-5 mt-2 flex justify-between items-center">
                                <span className="text-xs bg-indigo-600 text-white uppercase hover:bg-white hover:text-indigo-600 transition ease-in-out duration-500"></span>
                                <div className="text-white font-regular flex flex-row justify-start mt-8">
                                    <svg
                                        className="cursor-pointer"
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="60"
                                        width="60"
                                        viewBox="0 0 384 512"
                                        onClick={handleImageRemoval}
                                    >
                                        <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                                    </svg>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="mt-6 h-full">
                        <textarea
                            maxLength={150}
                            placeholder="Article Title"
                            value={articleData.title}
                            style={{ height: `${textareaHeight}px` }}
                            className={`w-full px-2 text-4xl bg-transparent  font-bold focus:outline-none article-title`}
                            onKeyDown={(event) => handleTextareaSize(event)}
                            onChange={(event) => {
                                setIsTypingCompleted(true);
                                setArticleData({
                                    ...articleData,
                                    title: event.target.value,
                                });
                            }}
                        ></textarea>
                        <div className="flex flex-row gap-4">
                            {isSubtitlePresent && (
                                <>
                                    <textarea
                                        maxLength={100}
                                        placeholder="Article Subtitle"
                                        value={articleData.subtitle}
                                        style={{
                                            height: `${textareaHeight}px`,
                                        }}
                                        className={`w-full px-2 text-2xl bg-transparent font-bold focus:outline-none article-subtitle`}
                                        onKeyDown={(event) =>
                                            handleTextareaSize(event)
                                        }
                                        onChange={(event) => {
                                            setIsTypingCompleted(true);
                                            setArticleData({
                                                ...articleData,
                                                subtitle: event.target.value,
                                            });
                                        }}
                                    ></textarea>
                                    <svg
                                        className="mt-4 cursor-pointer"
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="24"
                                        width="24"
                                        viewBox="0 0 384 512"
                                        onClick={toggleSubtitleField}
                                    >
                                        <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                                    </svg>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div id="editorjs" className="max-w-full h-full mt-4"></div>
            </section>
            <div
                className="fixed z-10 overflow-y-auto top-0 w-full left-0 hidden"
                id="image-modal"
            >
                <div className="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div className="fixed inset-0 transition-opacity">
                        <div className="absolute inset-0 bg-gray-900 opacity-75" />
                    </div>
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
                        &#8203;
                    </span>
                    <div
                        className="inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                        role="dialog"
                        aria-modal="true"
                        aria-labelledby="modal-headline"
                    >
                        <div className="mb-8 mr-4 cursor-pointer">
                            <svg
                                className="cursor-pointer mt-4 float-right"
                                xmlns="http://www.w3.org/2000/svg"
                                height="20"
                                width="20"
                                viewBox="0 0 384 512"
                                onClick={() => toggleModal('image-modal')}
                            >
                                <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                            </svg>
                        </div>
                        <div className="bg-white pt-2 pb-4 sm:p-6 sm:pb-4">
                            <div className="rounded border mx-auto">
                                <ul
                                    id="tabs"
                                    className="inline-flex pt-2 px-1 w-full border-b"
                                >
                                    <li className="bg-white px-4 text-gray-800 font-semibold py-2 rounded-t border-t border-r border-l -mb-px">
                                        <a id="default-tab" href="#first">
                                            Select Image
                                        </a>
                                    </li>
                                    <li className="px-4 text-gray-800 font-semibold py-2 rounded-t">
                                        <a href="#second">Upload by url</a>
                                    </li>
                                </ul>

                                <div id="tab-contents">
                                    <div id="first" className="p-4">
                                        <div className="px-4 py-12 border border-dotted border-2">
                                            <div
                                                className="flex flex-row gap-2 items-center justify-center cursor-pointer hover:border-2 hover:border-gray-200 hover:rounded-md hover:py-2 hover:w-2/5 hover:ml-auto hover:mr-auto"
                                                onClick={() => {
                                                    document
                                                        .getElementById(
                                                            'cover-image-select'
                                                        )
                                                        ?.click();
                                                }}
                                            >
                                                <svg
                                                    className="mt-1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    height="16"
                                                    width="20"
                                                    viewBox="0 0 640 512"
                                                >
                                                    <path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z" />
                                                </svg>
                                                <span>Upload Image</span>
                                                <input
                                                    type="file"
                                                    name="cover-image"
                                                    id="cover-image-select"
                                                    hidden
                                                    onChange={
                                                        handleCoverImageUpload
                                                    }
                                                />
                                            </div>
                                            <div className="text-center mt-4">
                                                <span className="text-gray-600 text-sm">
                                                    Recommended Image Size: 200
                                                    x 230
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="second" className="hidden p-4">
                                        <div className="px-4 py-4 border border-dotted border-2">
                                            <div className="flex flex-row gap-2">
                                                <input
                                                    type="text"
                                                    className="w-full h-12 border border-gray-200 rounded-md px-2"
                                                    onChange={(e) => {
                                                        setImageUrlInput(
                                                            e.target.value
                                                        );
                                                    }}
                                                    placeholder="Enter image Url"
                                                />
                                                <Button
                                                    classValue="inline-flex items-center justify-center px-4 py-2 text-sm font-semibold leading-6 text-white transition duration-150 ease-in-out rounded-md shadow bg-blue-500 p-2 h-12 rounded-md text-white text-lg font-bold focus:border border-gray-700"
                                                    label="Upload"
                                                    onClick={
                                                        handleUrlImageUpload
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                className="fixed z-10 overflow-y-auto top-0 w-full left-0 hidden"
                id="publish-modal"
            >
                <div className="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div className="fixed inset-0 transition-opacity">
                        <div className="absolute inset-0 bg-gray-900 opacity-75" />
                    </div>
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
                        &#8203;
                    </span>
                    <div
                        className="inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                        role="dialog"
                        aria-modal="true"
                        aria-labelledby="modal-headline"
                    >
                        <div className="mb-8 mr-4 cursor-pointer">
                            <svg
                                className="cursor-pointer mt-4 float-right"
                                xmlns="http://www.w3.org/2000/svg"
                                height="20"
                                width="20"
                                viewBox="0 0 384 512"
                                onClick={() => toggleModal('publish-modal')}
                            >
                                <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                            </svg>
                        </div>
                        <div className="bg-white pt-2 pb-4 sm:p-6 sm:pb-4 text-center">
                            <h3>Coming soon...</h3>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditorPlayground;

import userReducer from "./user";
import articleReducer from "./article";
import storage from 'redux-persist/lib/storage';
import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import {
    persistReducer,
    FLUSH,
    // REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist'

const persistConfig = {
    key: 'root',
    version: 1,
    storage,
}

export interface RootState {
    userData: ReturnType<typeof userReducer>;
    articleData: ReturnType<typeof articleReducer>;
}
  
const rootReducers = combineReducers({
    userData: userReducer,
    articleData: articleReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducers);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        ignoredActions: [FLUSH, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
  
export default store;
// External imports
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import editorJsHtml from 'editorjs-html';
import Skeleton from 'react-loading-skeleton';

// Internal imports
import './css/article.css';
import Layout from '../../components/layout/main/Layout';
import { fetchArticleData } from '../../services/article';
import { checklistParser, codeParser } from '../../helpers/templateHelper';
import ArticlePreview from '../../components/dataDisplay/ArticlePreview';

const has = Object.prototype.hasOwnProperty;

const EditorJsToHtml = editorJsHtml({
    checklist: checklistParser,
    code: codeParser,
});
let html: any = [];

export default function PreviewArticle() {
    const params = useParams();

    const draftData = useRef<any>();

    if (draftData.current && has.call(draftData.current, 'time')) {
        html = EditorJsToHtml.parse(draftData.current);
    }

    const [isDataLoading, setIsDataLaoding] = useState(true);
    const [articleData, setArticleData] = useState({
        title: '',
        subtitle: '',
        coverImageUrl: '',
    });

    // Fetch article and parse to editorjs-html parser
    useEffect(() => {
        const fetchArticle = async () => {
            if (params.id) {
                const { status, data } = await fetchArticleData(params.id);
                if (status === 200) {
                    const { title, subtitle, coverImageUrl, content } = data;
                    draftData.current = content;

                    setArticleData({
                        ...articleData,
                        title,
                        subtitle,
                        coverImageUrl,
                    });
                    setIsDataLaoding(false);
                }

                return false;
            }
        };

        fetchArticle();
    }, [params]);

    if (isDataLoading) {
        return (
            <Layout>
                <section className="bg-white p-4 text-black">
                    <Skeleton count={8} />
                </section>
            </Layout>
        );
    }

    return (
        <Layout>
            <ArticlePreview articleData={articleData} html={html} />
        </Layout>
    );
}

const initialState = {
    drafts: [],
    savedArticles: [],
}

const articleReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case 'SAVE_USER_DRAFT_ARTICLES':
            const draftsArticles = action.payload.sort((a: any, b: any) => a.id - b.id);
            return {
                ...state,
                drafts: draftsArticles,
            }
        case 'STORE_USER_SAVED_ARTICLES':
            const userSavedArticles = action.payload.sort((a: any, b: any) => a.id - b.id);
            return {
                ...state,
                savedArticles: userSavedArticles,
            }
        default:
            return state;
    }
}

export default articleReducer;
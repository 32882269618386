// External imports
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// Internal imports
import Layout from "../../components/layout/main/Layout";
import { RootState } from "../../store";
import { getTimeDifferenceString } from "../../helpers/articleHelper";

export default function SavedArticles() {
    let selectedDraftId: string;
    const navigate = useNavigate();
    const articleData = useSelector((state: RootState) => state.articleData);

    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    const handleIconClick = (index: number) => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    const toggleModal = () => {
        document.getElementById('publish-modal')?.classList.toggle('hidden')
    }

    const promptDeletion = async (id: string) => {
        selectedDraftId = id;
        toggleModal();
    };

    return (
        <Layout>
            <div className="mb-4 flex flex-row justify-between">
                <h4 className="text-md dark:text-gray-300">My Articles</h4>
                <h6 className="text-xs dark:text-gray-300">{new Date().toDateString()}</h6>
            </div>
            <hr />

            {articleData.savedArticles.length > 0 ? (
                <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-4 mt-4">
                    {
                        articleData.savedArticles.map((article: any, index: number) => {
                            return (
                                <div className="flex flex-col shadow-md rounded-xl">
                                    <div className="h-52 max-h-52 bg-white flex items-center justify-center rounded-t-xl overflow-hidden">
                                        <div className="w-48 max-h-32">
                                            <img className="rounded-xl w-full h-full object-cover" src={article.coverImageUrl ? article.coverImageUrl : "https://images.unsplash.com/photo-1708793699440-67fa853abd4d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHw0fHx8ZW58MHx8fHx8"} />
                                        </div>
                                    </div>
                                    <div className="flex flex-col h-28 py-3 px-4 bg-brand-light rounded-b-xl dark:bg-gray-900">
                                        <div className="flex items-center justify-between">
                                            <p className="font-bold text-md cursor-pointer text-gray-600 dark:text-gray-300">{article.title ? article.title : 'Untitled'}</p>
                                            <div className="flex items-center">
                                                <div className="relative">
                                                    <span className="cursor-pointer" onClick={() => handleIconClick(index)}>
                                                        <svg className="w-5 h-5 text-gray-600 dark:text-gray-300" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z" fill="currentColor"></path>
                                                            <path d="M19 14C20.1046 14 21 13.1046 21 12C21 10.8954 20.1046 10 19 10C17.8954 10 17 10.8954 17 12C17 13.1046 17.8954 14 19 14Z" fill="currentColor"></path>
                                                            <path d="M5 14C6.10457 14 7 13.1046 7 12C7 10.8954 6.10457 10 5 10C3.89543 10 3 10.8954 3 12C3 13.1046 3.89543 14 5 14Z" fill="currentColor"></path>
                                                        </svg>
                                                    </span>
                                                    {
                                                        activeIndex === index && <div className="flex flex-col absolute rounded-lg shadow-md border-x border-y bg-white text-white border-gray-400 z-10 right-0 w-32 overflow-hidden mt-2">
                                                            <div className="flex items-center group whitespace-nowrap cursor-pointer pr-3 pl-2 py-2 text-sm text-gray-400 hover:text-gray-300 hover:bg-gray-700 dark:hover:bg-gray-300" onClick={() => navigate(`/articles/preview/${article.id}`)}>
                                                                <span>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" fill="currentColor" className="w-3 h-3 mr-1 dark:text-gray-600">
                                                                        <path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"/>
                                                                    </svg>
                                                                </span>
                                                                <span className="dark:text-gray-600">View</span>
                                                            </div>
                                                            <div className="flex items-center group whitespace-nowrap cursor-pointer pr-3 pl-2 py-2 text-sm text-gray-400 hover:text-gray-300 hover:bg-gray-700 dark:hover:bg-gray-300" onClick={() => promptDeletion(article.id)}>
                                                                <span>
                                                                    <svg fill="none" className="w-3 h-3 mr-1 dark:text-gray-600" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M10 15L10 12" stroke="currentColor" className="text-gray-400 group-hover:text-gray-200" stroke-width="2" stroke-linecap="round"></path>
                                                                        <path d="M14 15L14 12" stroke="currentColor" className="text-gray-400 group-hover:text-gray-200" stroke-width="2" stroke-linecap="round"></path>
                                                                        <path d="M3 7H21V7C20.0681 7 19.6022 7 19.2346 7.15224C18.7446 7.35523 18.3552 7.74458 18.1522 8.23463C18 8.60218 18 9.06812 18 10V16C18 17.8856 18 18.8284 17.4142 19.4142C16.8284 20 15.8856 20 14 20H10C8.11438 20 7.17157 20 6.58579 19.4142C6 18.8284 6 17.8856 6 16V10C6 9.06812 6 8.60218 5.84776 8.23463C5.64477 7.74458 5.25542 7.35523 4.76537 7.15224C4.39782 7 3.93188 7 3 7V7Z" stroke="currentColor" className="text-gray-400 group-hover:text-gray-200" stroke-width="2" stroke-linecap="round"></path>
                                                                        <path d="M10.0681 3.37059C10.1821 3.26427 10.4332 3.17033 10.7825 3.10332C11.1318 3.03632 11.5597 3 12 3C12.4403 3 12.8682 3.03632 13.2175 3.10332C13.5668 3.17033 13.8179 3.26427 13.9319 3.37059" stroke="currentColor" className="text-gray-400 group-hover:text-gray-200" stroke-width="2" stroke-linecap="round"></path>
                                                                    </svg>
                                                                </span>
                                                                <span className="dark:text-gray-600">Delete Article</span>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex items-center justify-between mt-4">
                                            <p className="flex items-center justify-between">
                                                <span>
                                                    <svg className="w-4 h-4 text-brand" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433284 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7363 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM14 11H10C9.73479 11 9.48043 10.8946 9.2929 10.7071C9.10536 10.5196 9 10.2652 9 10V6C9 5.73478 9.10536 5.48043 9.2929 5.29289C9.48043 5.10536 9.73479 5 10 5C10.2652 5 10.5196 5.10536 10.7071 5.29289C10.8946 5.48043 11 5.73478 11 6V9H14C14.2652 9 14.5196 9.10536 14.7071 9.29289C14.8946 9.48043 15 9.73478 15 10C15 10.2652 14.8946 10.5196 14.7071 10.7071C14.5196 10.8946 14.2652 11 14 11Z" fill="currentColor"></path>
                                                    </svg>
                                                </span>
                                                <span className="ml-2 text-xs text-gray-600 text-sm dark:text-gray-300">Published {getTimeDifferenceString(article.updatedAt)}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            ) : (
                <p className="text-center my-4">No draft available.</p>
            )}
        </Layout>
    );
}

// External imports
import Chart from "react-apexcharts";

type DataChartProps = {
    categoriesData: string[];
    seriesData: number[];
}

export default function Datachart({categoriesData, seriesData}: DataChartProps) {
    // Setup chart data
    const chartInfo = {
        options: {
            chart: {
                id: 'area-chart',
                height: 150,
            },
            xaxis: {
                categories: categoriesData,
            },
            dataLabels: {
                enabled: false
            },
        },
        series: [
            {
              name: 'Series 1',
              data: seriesData,
            },
        ],
    }

    const totalArticlesCount = () => {
        return seriesData.reduce((a, b) => a + b);
    }

    return (
        <div className="mt-4 bg-default rounded-md">
            <div className="px-4 pb-4">
                <div className="flex justify-between pt-4">
                    <div>
                        <h4 className="text-md font-bold mb-2 dark:text-white">Article Statistics</h4>
                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="4" viewBox="0 0 48 4" fill="none">
                            <path d="M0 2H48" stroke="#1565D8" strokeWidth="3"></path>
                        </svg>
                    </div>
                </div>
                <Chart
                    options={chartInfo.options}
                    series={chartInfo.series}
                    type="area"
                    height={400}
                />
                <h5 className="font-bold text-sm dark:text-white">Total Chart Data Count : {seriesData.length > 0 ? totalArticlesCount() : 0}</h5>
            </div>
        </div>
    )
}
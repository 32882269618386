import List from '@editorjs/list';
import imageTool from "@editorjs/image";
import quoteUmd from "@editorjs/quote";
import checklistUmd from "@editorjs/checklist";
import codeUmd from 'editorjs-code-docwrite';
import inlineCodeUmd from '@editorjs/inline-code'

export const EDITOR_TOOLS = {
    list: {
        class: List,
        inlineToolbar: true,
        config: {
            defaultStyle: 'unordered'
        },
        shortcut: 'CMD+SHIFT+L',
    },
    image: {
        class: imageTool,
        config: {
            endpoints: {
                byFile: `${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD}/article/content/upload-image`,
            },
            captionPlaceholder: 'Image caption'
        },
        shortcut: 'CMD+SHIFT+I',
    },
    quote: {
        class: quoteUmd,
        inlineToolbar: true,
        config: {
            quotePlaceholder: 'Enter a quote',
            captionPlaceholder: 'Quote\'s author',
        },
        shortcut: 'CMD+SHIFT+Q',
    },
    checklist: {
        class: checklistUmd,
        inlineToolbar: true,
        shortcut: 'CMD+SHIFT+T',
    },
    inlineCode: {
        class: inlineCodeUmd,
        inlineToolbar: true,
    },
    code: {
        class: codeUmd,
        inlineToolbar: false,
        showlinenumbers: false,
        shortcut: 'CMD+SHIFT+C',
    },
};
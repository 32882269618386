// External imports
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// Internal imports
import Button from "../form/Button";
import { saveTemplate } from "../../services/template";
import { TemplateCardProps } from "../../interfaces/template";

export default function TemplateCard({
    image,
    title,
    color,
    category,
    action,
    templateId,
}: TemplateCardProps) {
    const navigate = useNavigate();

    const handleTemplatePreview = (id: string | number) => {
        navigate(`/templates/preview/${id}`);
    };

    const saveSelectedTemplate = async () => {
        const { status, message } = await saveTemplate(templateId);
        if (status !== 200) {
            toast.error(message);
            return;
        }

        toast.success(message);
        navigate("/templates/saved");
        return;
    };

    return (
        <div className="bg-white relative mb-2 cursor-pointer shadow-md rounded-lg overflow-hidden" key={templateId}>
            <div className="group">
                <div
                    className="flex rounded-t-lg flex-col py-8 items-center justify-center"
                    style={{ backgroundColor: color }}
                >
                    <img
                        src={image}
                        alt="Popular template"
                        width={100}
                        className="h-[70px] w-[70px] sm:w-[100px] sm:h-[100px]"
                    />
                </div>
                <div className="top-0 w-full right-0 left-0 h-full flexy-col items-center justify-center z-[10000] group-hover:flex hidden absolute">
                    <div className="absolute inset-0 bg-black bg-opacity-0 h-[55%] sm:h-[60%] group-hover:bg-opacity-30 transition-all duration-300"></div>
                    <div className="px-6 flex-col absolute bg-white w-full bottom-0 justify-center flex items-center py-4">
                        <Button
                            classValue="inline-flex items-center justify-center px-2 text-sm font-semibold leading-6 text-white transition duration-150 ease-in-out rounded-md shadow bg-brand py-3 px-4 h-10 rounded-md text-white text-lg font-bold focus:border border-gray-700"
                            label="Preview Template"
                            onClick={() => handleTemplatePreview(templateId)}
                        />
                    </div>
                </div>
            </div>
            <div
                className={`flex ${
                    action === "use" ? "justify-center" : "justify-between"
                } text-black items-center py-4 px-6 gap-2`}
            >
                <div className="w-[90%]">
                    <p className="text-lg sm:text-md font-semibold">{title.slice(0, 29) + '...'}</p>
                    <small>Category: {category}</small>
                </div>
                {action === "view" && (
                    <div
                        className="border rounded-sm p-2 w-[10%]"
                        onClick={() => saveSelectedTemplate()}
                    >
                        <img
                            src={require("./../../assets/dashboard/bookmark-icon.png")}
                            alt="Bookmark"
                        />
                    </div>
                )}
            </div>
        </div>
    );
}

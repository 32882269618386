type ImageType = string; 

function shuffleArray<T>(array: T[]): T[] {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

export const getRandomImage = (imagesData: string[]): ImageType => {
    const images: ImageType[] = imagesData;
    let shuffledImages: ImageType[] = shuffleArray([...images]);

    if (shuffledImages.length === 0) {
        shuffledImages = shuffleArray([...images]);
    }
    return shuffledImages.pop()!;
};
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { LocalStorage } from "../../helpers/localStorageHelper";

export default function SsoSuccess() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('accessToken');
        const refreshToken = urlParams.get('refreshToken');
        const userId = urlParams.get('userId');
        const firstName = urlParams.get('firstName');
        const lastName = urlParams.get('lastName');
        const email = urlParams.get('email');
        const isLoggedIn = urlParams.get('isLoggedIn');

        document.cookie = `accessToken=${token}; max-age=${18000 * 60 * 10}; path=/; domain=localhost; secure; samesite=None;`;
        document.cookie = `refreshToken=${refreshToken}; max-age=${18000 * 60 * 10}; path=/; domain=localhost; secure; samesite=None;`;

        LocalStorage.setItem('refresh_token', refreshToken);
        dispatch({ type: "SAVE_USER_DATA", payload: {
            firstName,
            lastName,
            email,
            isLoggedIn,
            id: userId,
        } });
        
        navigate('/dashboard');
    }, []);
    return (
        <>
            
        </>
    )
}
// External imports
import { ChangeEvent, ChangeEventHandler, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";

// Internal imports
import Logo from "../../assets/logo.svg";
import VectorIconBottom from "../../assets/onboarding/bg-vector-bottom.svg";
import vectorIconTop from "../../assets/onboarding/bg-vector-top.svg";
import DividerText from "../../assets/onboarding/divider-text.svg";
import GithubIcon from "../../assets/onboarding/github.svg";
import MailIcon from "../../assets/onboarding/gmail.svg";
import TextField from "../../components/form/TextField";
import SelectField from "../../components/form/Select";
import Button from "../../components/form/Button";
import Toastify from "../../components/feedback/Alert";
import { RegisterProps } from "../../interfaces/onboarding";
import { registerUser } from "../../services/onboarding";
import { registerValidationSchema } from "../../validators/onboarding";
import { initiateSsoSignup } from "../../services/onboarding";

export default function Register() {
    const navigate = useNavigate();
    const [docwriteAccountPlan, setDocwriteAccountPlan] = useState([
        {
            id: 1,
            name: 'beginner'
        },
        {
            id: 2,
            name: 'pro'
        },
        {
            id: 3,
            name: 'enterprise'
        }
    ]);
    const [formData, setFormdata] = useState<RegisterProps>({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
        accountPlan: "beginner"
    });
    const [isLoading, setIsLoading] = useState(false);
    const [togglePassword, setTogglePassword] = useState(false);
    const [confirmTogglePassword, setConfirmTogglePassword] = useState(false);

    // Bootstrap react hook form validation
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<RegisterProps>({
        resolver: yupResolver(registerValidationSchema),
    });

    // Update form values with user typed values
    const handleChange: ChangeEventHandler<HTMLInputElement> = (
        event: ChangeEvent<HTMLInputElement>
    ) => {
        setFormdata({
            ...formData,
            [event.target.name]: event.target.value,
        });
    };

    const handleSelectChange: ChangeEventHandler<HTMLSelectElement> = (event: ChangeEvent<HTMLSelectElement>) => {
        setFormdata({
            ...formData,
            [event.target.name]: event.target.value,
        });
    }

    // Function to submit form
    const onSubmit = async (data: RegisterProps) => {
        // Check if password matches with confirm password
        setIsLoading(true);
        if (data.password !== data.confirmPassword) {
            toast.error("Password and confirm password do not match");
            setIsLoading(false);
            return;
        }

        try {
            // Call API service for registration
            const { status, message } = await registerUser(formData);
            if (status !== 200) {
                toast.error(message);
                setIsLoading(false);
                return;
            }

            toast.success(`${message}. Redirecting to login...`);
            setTimeout(() => {
                navigate("/");
            }, 3000);
        } catch (err) {
            toast.error(
                "Unable to process form submission. Kindly check all fields"
            );
            setIsLoading(false);
            return;
        }
    };

    const handleSsoSignup = async () => {
        const { status, message, data } = await initiateSsoSignup();
        if (status !== 200) {
            toast.error(message);
            return;
        }

        window.location.href = data;
    };

    return (
        <>
            <div className="bg-gradient-to-r from-blue-100 to-blue-100 bg-opacity-10 lg:h-screen">
                <img
                    src={vectorIconTop}
                    className="absolute top-0 right-0 float-right"
                    alt="Vector top"
                />
                <div
                    className="bg-cover bg-no-repeat bg-center"
                    style={{
                        backgroundImage: `url('../../assets/onboarding/bg.png')`,
                    }}
                >
                    <div className="px-4 lg:px-8 py-4">
                        <div className="mt-4">
                            <img
                                src={Logo}
                                alt="Logo"
                                width={100}
                                height={100}
                            />
                        </div>
                        <div className="mt-2 p-6 bg-white rounded-lg w-[95%] sm:w-[90%] md:w-[85%] lg:w-[60%] mx-auto max-w-[1600px]">
                            <h3 className="text-center font-bold text-xl">
                                Sign up
                            </h3>
                            <div className="mt-6 lg:mt-8 mb-4 px-1 lg:px-4">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="lg:grid lg:grid-cols-2 lg:gap-8 mt-4 md:flex md:flex-col">
                                        <div className="mt-2">
                                            <p className="mb-2 font-bold text-xs lg:text-md">
                                                First name
                                            </p>
                                            <TextField
                                                type="text"
                                                name="firstName"
                                                placeholder="John"
                                                register={register}
                                                errors={errors}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="mt-2">
                                            <p className="mb-2 font-bold text-xs lg:text-md">
                                                Last name
                                            </p>
                                            <TextField
                                                type="text"
                                                name="lastName"
                                                placeholder="Doe"
                                                register={register}
                                                errors={errors}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="lg:grid lg:grid-cols-2 lg:gap-8 mt-4 md:flex md:flex-col">
                                        <div className="mt-2">
                                            <p className="mb-2 font-bold text-xs lg:text-md">
                                                Email
                                            </p>
                                            <div className="relative">
                                                <TextField
                                                    type="email"
                                                    name="email"
                                                    placeholder="johndoe@gmail.com"
                                                    register={register}
                                                    errors={errors}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="mt-2">
                                            <p className="mb-2 font-bold text-xs lg:text-md">
                                                Account Plan
                                            </p>
                                            <div className="relative">
                                                <SelectField
                                                    data={docwriteAccountPlan}
                                                    name="accountPlan"
                                                    defaultValue={formData.accountPlan}
                                                    register={register}
                                                    errors={errors}
                                                    onChange={handleSelectChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="lg:grid lg:grid-cols-2 lg:gap-8 mt-4 md:flex md:flex-col">
                                        <div className="mt-2">
                                            <p className="mb-2 font-bold text-xs lg:text-md">
                                                Password
                                                <br />
                                                <span className="font-light">
                                                    Your password must contain:
                                                    Minimum character of 8 , one
                                                    lowercase, one uppercase,
                                                    one number and one symbol
                                                </span>
                                            </p>
                                            <div className="relative">
                                                <TextField
                                                    type={
                                                        togglePassword
                                                            ? "text"
                                                            : "password"
                                                    }
                                                    name="password"
                                                    placeholder="**********"
                                                    register={register}
                                                    errors={errors}
                                                    onChange={handleChange}
                                                />
                                                <div
                                                    onClick={() => {
                                                        setTogglePassword(
                                                            !togglePassword
                                                        );
                                                    }}
                                                    className="absolute right-4 bottom-4"
                                                >
                                                    {togglePassword ? (
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            height="1em"
                                                            viewBox="0 0 576 512"
                                                        >
                                                            <path d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z" />
                                                        </svg>
                                                    ) : (
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            height="1em"
                                                            viewBox="0 0 640 512"
                                                        >
                                                            <path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zm151 118.3C226 97.7 269.5 80 320 80c65.2 0 118.8 29.6 159.9 67.7C518.4 183.5 545 226 558.6 256c-12.6 28-36.6 66.8-70.9 100.9l-53.8-42.2c9.1-17.6 14.2-37.5 14.2-58.7c0-70.7-57.3-128-128-128c-32.2 0-61.7 11.9-84.2 31.5l-46.1-36.1zM394.9 284.2l-81.5-63.9c4.2-8.5 6.6-18.2 6.6-28.3c0-5.5-.7-10.9-2-16c.7 0 1.3 0 2 0c44.2 0 80 35.8 80 80c0 9.9-1.8 19.4-5.1 28.2zm9.4 130.3C378.8 425.4 350.7 432 320 432c-65.2 0-118.8-29.6-159.9-67.7C121.6 328.5 95 286 81.4 256c8.3-18.4 21.5-41.5 39.4-64.8L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5l-41.9-33zM192 256c0 70.7 57.3 128 128 128c13.3 0 26.1-2 38.2-5.8L302 334c-23.5-5.4-43.1-21.2-53.7-42.3l-56.1-44.2c-.2 2.8-.3 5.6-.3 8.5z" />
                                                        </svg>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-2">
                                            <p className="mb-2 font-bold text-xs lg:text-md">
                                                Confirm Password
                                                <br />
                                                <br />
                                                <span className="font-light">
                                                    Your confirm password must
                                                    match your chosen password
                                                </span>
                                            </p>
                                            <div className="relative">
                                                <TextField
                                                    type={
                                                        confirmTogglePassword
                                                            ? "text"
                                                            : "password"
                                                    }
                                                    name="confirmPassword"
                                                    placeholder="*********"
                                                    register={register}
                                                    errors={errors}
                                                    onChange={handleChange}
                                                />
                                                <div
                                                    onClick={() => {
                                                        setConfirmTogglePassword(
                                                            !confirmTogglePassword
                                                        );
                                                    }}
                                                    className="absolute right-4 bottom-4"
                                                >
                                                    {confirmTogglePassword ? (
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            height="1em"
                                                            viewBox="0 0 576 512"
                                                        >
                                                            <path d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z" />
                                                        </svg>
                                                    ) : (
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            height="1em"
                                                            viewBox="0 0 640 512"
                                                        >
                                                            <path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zm151 118.3C226 97.7 269.5 80 320 80c65.2 0 118.8 29.6 159.9 67.7C518.4 183.5 545 226 558.6 256c-12.6 28-36.6 66.8-70.9 100.9l-53.8-42.2c9.1-17.6 14.2-37.5 14.2-58.7c0-70.7-57.3-128-128-128c-32.2 0-61.7 11.9-84.2 31.5l-46.1-36.1zM394.9 284.2l-81.5-63.9c4.2-8.5 6.6-18.2 6.6-28.3c0-5.5-.7-10.9-2-16c.7 0 1.3 0 2 0c44.2 0 80 35.8 80 80c0 9.9-1.8 19.4-5.1 28.2zm9.4 130.3C378.8 425.4 350.7 432 320 432c-65.2 0-118.8-29.6-159.9-67.7C121.6 328.5 95 286 81.4 256c8.3-18.4 21.5-41.5 39.4-64.8L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5l-41.9-33zM192 256c0 70.7 57.3 128 128 128c13.3 0 26.1-2 38.2-5.8L302 334c-23.5-5.4-43.1-21.2-53.7-42.3l-56.1-44.2c-.2 2.8-.3 5.6-.3 8.5z" />
                                                        </svg>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex justify-center mt-4">
                                        <img src={DividerText} alt="Divider" />
                                    </div>
                                    <div className="lg:grid lg:grid-cols-2 lg:gap-8 mt-4 flex flex-col gap-2">
                                        <div
                                            className="flex flex-row gap-2 bg-brand-light px-4 py-3 rounded-md sm:mt-4 cursor-pointer"
                                            onClick={handleSsoSignup}
                                        >
                                            <img
                                                src={MailIcon}
                                                alt="Mail icon"
                                            />
                                            <span className="lg:text-md mt-1 text-xs font-semibold">
                                                Sign up with Google
                                            </span>
                                        </div>
                                        <div className="flex flex-row gap-2 bg-brand-light px-4 py-3 rounded-md sm:mt-4">
                                            <img
                                                src={GithubIcon}
                                                alt="Github icon"
                                            />
                                            <span className="lg:text-md mt-1 text-xs font-semibold">
                                                Sign up with Github
                                            </span>
                                        </div>
                                    </div>
                                    <div className="mt-6">
                                        <Button
                                            classValue="inline-flex items-center justify-center px-4 py-2 text-sm font-semibold leading-6 text-white transition duration-150 ease-in-out rounded-md shadow bg-brand p-2 w-full h-12 rounded-md text-white text-lg font-bold focus:border border-gray-700"
                                            loading={isLoading}
                                            label="Sign up"
                                        />
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="mt-2">
                            <p className="text-center text-sm">
                                Do you have an account?{" "}
                                <a href="/" className="text-brand font-bold">
                                    Login
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
                <img
                    src={VectorIconBottom}
                    className="absolute bottom-0 left-0 float-right"
                    alt="Vector bottom"
                />
            </div>
            <Toastify />
        </>
    );
}

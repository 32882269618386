export const LocalStorage = {
    setItem(key: string, payload: any) {
        return localStorage.setItem(key, JSON.stringify(payload));
    },
    getItem(key: string) {
        const data = localStorage.getItem(key);
        const result = data ? JSON.parse(data) : null
        return result;
    },
    removeItem(key: string) {
       localStorage.removeItem(key);
       return true;
    }
}
// External imports
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';

// Internal imports
import Layout from '../../components/layout/main/Layout';
import TemplateCard from '../../components/dataDisplay/TemplateCard';
import apiDocsImage from '../../assets/dashboard/api-docs.png';
import howToGuidesImage from '../../assets/dashboard/how-to-guides.png';
import codeSamplesImage from '../../assets/dashboard/code-samples.png';
import caseStudy from '../../assets/dashboard/case-study.png';
import { RootState } from '../../store';
import { TemplateDataProps } from '../../interfaces/template';
import { fetchTemplates } from '../../services/template';
import { getRandomColor } from '../../helpers/templateHelper';
import { getRandomImage } from '../../helpers/globalHeper';

export default function BrowseTemplates() {
    const userData = useSelector((state: RootState) => state.userData);
    console.log(userData.user.accountPlan)

    const [templates, setTemplates] = useState<TemplateDataProps[]>([]);
    const [skeletonState, setSkeletonState] = useState({
        template_data_retrieved: false,
    });

    useEffect(() => {
        const fetchAllTemplates = async () => {
            const { status, data } = await fetchTemplates();
            if (status !== 200) {
                setTemplates([]);
                return;
            }

            setTemplates(data);
            setSkeletonState((prevData) => {
                const payload = {
                    ...prevData,
                    template_data_retrieved: true,
                };

                return payload;
            });
        };

        fetchAllTemplates();
    }, []);

    const handleRandomImageRetrieval = () => {
        const data: string = getRandomImage([
            apiDocsImage,
            howToGuidesImage,
            codeSamplesImage,
            caseStudy,
        ]);
        return data;
    };

    return (
        <Layout>
            <div>
                <div className="mb-2 flex flex-row justify-between">
                    <h4 className="text-md text-black dark:text-gray-300">
                        Browse Templates
                    </h4>
                    <h6 className="text-xs dark:text-gray-300">
                        {new Date().toDateString()}
                    </h6>
                </div>
                <p className="mb-4 text-sm dark:text-gray-300">
                    Choose your choice of template based on the categories
                    listed below
                </p>
            </div>
            <hr />
            <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-4 mt-4">
                {
                    userData.user.accountPlan !== 'beginner' && <div className="bg-white mb-2 shadow-md rounded-lg">
                        <div className="flex flex-col items-center text-black text-center justify-center px-4 py-6">
                            <img
                                src={require('../../assets/dashboard/create-template.png')}
                                alt="Popular template"
                                width={100}
                                className="my-2"
                            />
                            <p className="my-2 text-lg sm:text-xl font-bold">
                                Create Blank
                            </p>
                            <div className="mt-4 text-sm">
                                <p>Can’t find a template of your choice ?</p>
                                <p className="underline text-[#2672D4] mt-1 cursor-pointer">
                                    Create a custom template
                                </p>
                            </div>
                        </div>
                    </div>
                }
                
                {!skeletonState.template_data_retrieved
                    ? [2, 3, 4, 5].map((data) => (
                          <div className="bg-white relative mb-2 cursor-pointer shadow-md rounded-lg overflow-hidden p-4">
                              <Skeleton count={8} />
                          </div>
                      ))
                    : templates.map((template: TemplateDataProps) => (
                          <TemplateCard
                              key={template.id}
                              title={template.name}
                              image={handleRandomImageRetrieval()}
                              action="view"
                              category={template.article_category.name}
                              color={getRandomColor()}
                              templateId={template.id}
                          />
                      ))}
            </div>
        </Layout>
    );
}
